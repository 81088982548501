import React from "react";
import styled from "styled-components";
import TextAreaFormField from "../../components/TextAreaFormField";
import TextFormField from "../../components/TextFormField";
import SelectFormField from "../../components/SelectFormField";
import { FormField } from "../../components/SharedStyles";
import {
  Landmark,
  LandmarkCategory,
  LandmarkCategoryType,
} from "../../core/Landmark";
import { PatchLandmarkRequest } from "../../core/Services";
import CoordinatePicker from "./CoordinatePicker";
import Coordinate from "../../core/Coordinate";

const CoordinatesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

interface LandmarkInformationProps {
  parkBoundary: Coordinate[] | undefined;
  selectedLandmark: Landmark | undefined;
  saveLandmarkChanges: (changes: PatchLandmarkRequest) => void;
}

export default function LandmarkInformation({
  parkBoundary,
  selectedLandmark,
  saveLandmarkChanges,
}: LandmarkInformationProps) {
  const categoryOptions = [
    { name: "Building", value: LandmarkCategory.Building },
    { name: "Point of interest", value: LandmarkCategory.PointOfInterest },
  ];

  if (typeof selectedLandmark === "undefined") {
    return null;
  }

  return (
    <>
      <h2>Landmark details</h2>
      <TextFormField
        id="name"
        type="text"
        label="Name"
        value={selectedLandmark.name}
        onChange={(e) => {
          saveLandmarkChanges({ name: e.target.value });
        }}
      />
      <SelectFormField
        id="category"
        options={categoryOptions}
        label="Category"
        value={selectedLandmark.category}
        onChange={(e) => {
          const value = e.target.value as LandmarkCategoryType;
          saveLandmarkChanges({ category: value });
        }}
      />
      <TextAreaFormField
        id="longDescription"
        label="Full description"
        value={selectedLandmark.longDescription}
        onChange={(e) => {
          saveLandmarkChanges({ longDescription: e.target.value });
        }}
      />
      <TextAreaFormField
        id="shortDescription"
        label="Simplified description"
        value={selectedLandmark.shortDescription}
        onChange={(e) => {
          saveLandmarkChanges({ shortDescription: e.target.value });
        }}
      />
      <TextFormField
        id="url"
        type="text"
        label="Photo URL"
        value={selectedLandmark.imageUrl}
        onChange={(e) => {
          saveLandmarkChanges({ imageUrl: e.target.value });
        }}
      />
      <TextFormField
        id="altText"
        type="text"
        label="Photo alt text"
        value={selectedLandmark.imageAltText}
        onChange={(e) => {
          saveLandmarkChanges({ imageAltText: e.target.value });
        }}
      />
      <TextFormField
        id="beaconId"
        type="number"
        min="1"
        step="1"
        label="Beacon ID"
        value={selectedLandmark.beaconId ?? ""}
        onChange={(e) => {
          saveLandmarkChanges({ beaconId: Number(e.target.value) });
        }}
      />
      <FormField>
        <label>Coordinates</label>
        <div style={{fontSize: '10pt', marginBottom: '0.25rem'}}>Click the map to set the position</div>
        <CoordinatePicker
          landmark={selectedLandmark}
          parkBoundary={parkBoundary}
          saveLandmarkChanges={saveLandmarkChanges}
        />
        <CoordinatesContainer>
          <TextFormField
            id="longitude"
            type="number"
            label="Longitude"
            min="-180"
            max="180"
            step="0.001"
            value={selectedLandmark.longitude ?? ""}
            onChange={(e) => {
              saveLandmarkChanges({ longitude: Number(e.target.value) });
            }}
          />
          <TextFormField
            id="latitude"
            type="number"
            label="Latitude"
            min="-90"
            max="90"
            step="0.001"
            value={selectedLandmark.latitude ?? ""}
            onChange={(e) => {
              saveLandmarkChanges({ latitude: Number(e.target.value) });
            }}
          />
        </CoordinatesContainer>
      </FormField>
    </>
  );
}
