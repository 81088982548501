import React, { useRef, useState, MouseEvent, FormEvent } from "react";
import styled from "styled-components";

import TextFormField from "../../components/TextFormField";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

interface RenameParkProps {
  parkName: string;
  visible: boolean;
  errorMessage?: string;
  onClose: ((value?: string) => void) | ((value?: string) => Promise<void>);
}

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export default function RenameParkModal({
  parkName,
  visible = false,
  errorMessage = "",
  onClose,
}: RenameParkProps) {
  const [errorText, setErrorText] = useState("");

  const nameField = useRef<HTMLInputElement>(null);

  function onOkay(e: FormEvent) {
    e.preventDefault();
    setErrorText("");
    const name = nameField.current?.value.trim();
    if (name === "") {
      setErrorText("Park name is a required field");
    } else {
      void onClose(name);
    }
  }

  function onCancel(e: MouseEvent) {
    e.preventDefault();
    void onClose();
  }

  return (
    <Modal visible={visible}>
      <h1>Rename app</h1>
      <form onSubmit={onOkay}>
        <TextFormField
          id="name"
          label="Park name"
          type="text"
          name="name"
          defaultValue={parkName}
          ref={nameField}
        />
        <ButtonRow>
          <Button type="submit" buttonStyle="primary">Save</Button>
          <Button type="button" onClick={onCancel}>
            Cancel
          </Button>
        </ButtonRow>
      </form>
      <p className="error-text">{errorText === "" ? errorMessage : errorText}</p>
    </Modal>
  );
}
