import { forwardRef } from "react";
import styled from "styled-components";
import { borderColor } from "./Theme";

type TextAreaProps = JSX.IntrinsicElements["textarea"];

const StyledTextArea = styled.textarea`
  display: block;
  padding: 0.5rem 1rem;
  width: 100%;
  border: 1px solid ${borderColor};
  border-radius: 5px;
  resize: vertical;
`;

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(props, ref) {
    return <StyledTextArea rows={5} {...props} ref={ref}></StyledTextArea>;
  }
);

export default TextArea;
