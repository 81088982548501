import React from "react";
import Nav from "./Nav";
import { Outlet } from "react-router-dom";
import RequireAuth from "../../components/RequireAuth";
export default function Layout() {
  return (
    <div>
      <Nav />
      <RequireAuth>
        <Outlet />
      </RequireAuth>
    </div>
  );
}
