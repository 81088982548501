import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { postRefresh, postSignIn } from "../core/Services";
import { LionsPrideUser } from "../core/User";
import LoadingScreen from "./LoadingScreen";

const localStoragePrefix = "lionspride";
export const idTokenKey = `${localStoragePrefix}-id-token`;
export const refreshTokenKey = `${localStoragePrefix}-refresh-token`;

export interface AuthContextType {
  user?: LionsPrideUser;
  signIn: (email: string, password: string) => Promise<void>;
  refresh: () => Promise<void>;
  signOut: () => void;
}

interface LoginResult {
  IdToken: string;
  AccessToken: string;
  RefreshToken?: string;
}

interface IdTokenPayload {
  sub: string;
  email_verified: boolean;
  iss: string;
  "cognito:username": string;
  given_name: string;
  origin_jti: string;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: number;
  exp: number;
  iat: number;
  family_name: string;
  jti: string;
  email: string;
}

export const AuthContext = createContext<AuthContextType>({
  user: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signIn: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refresh: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signOut: () => {},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseJwt(token: string): any {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<LionsPrideUser | undefined>();
  const [loading, setLoading] = useState(true);

  function setUserFromApiResponse(result: LoginResult) {
    const userData = parseJwt(result.IdToken) as IdTokenPayload;
    const user: LionsPrideUser = {
      id: userData.sub,
      idToken: result.IdToken,
      accessToken: result.AccessToken,
      email: userData.email,
      firstName: userData.given_name,
      lastName: userData.family_name,
    };
    setUser(user);

    localStorage.setItem(idTokenKey, result.IdToken);
    if (typeof result.RefreshToken === "string") {
      localStorage.setItem(refreshTokenKey, result.RefreshToken);
    }
  }

  const refresh = async function () {
    const idToken = localStorage.getItem(idTokenKey);
    const refreshToken = localStorage.getItem(refreshTokenKey);
    if (idToken === null || refreshToken === null) {
      return;
    }
    const result = await postRefresh(idToken, refreshToken);
    setUserFromApiResponse(result);
  };

  const signIn = async function (email: string, password: string) {
    const result = await postSignIn(email, password);
    setUserFromApiResponse(result);
  };

  const signOut = function () {
    setUser(undefined);
    localStorage.removeItem(idTokenKey);
    localStorage.removeItem(refreshTokenKey);
  };

  useEffect(() => {
    void (async () => {
      if (loading) {
        try {
          await refresh();
        } finally {
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const value = { user, refresh, signIn, signOut };

  if (loading) {
    return (
      <div style={{ height: "100vh" }}>
        <LoadingScreen />
      </div>
    );
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
