import React from "react";
import { forwardRef, ReactNode } from "react";
import styled from "styled-components";
import chroma from "chroma-js";
import {
  borderColor,
  containerColor,
  hoverColor,
  linkColor,
  primaryColor,
  textLight,
} from "./Theme";

type ButtonProps = JSX.IntrinsicElements["button"] & {
  children: ReactNode;
  buttonStyle?: "link" | "default" | "primary";
};

const StyledButton = styled.button`
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid ${borderColor};
  border-radius: 5px;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }
`;

const DefaultButton = styled(StyledButton)`
  background-color: ${containerColor};
  &:hover:not(:disabled) {
    background-color: ${hoverColor};
  }
  &:active:not(:disabled) {
    background-color: ${chroma(hoverColor).darken(0.5).hex()};
  }
`;

const PrimaryButton = styled(StyledButton)`
  background-color: ${primaryColor};
  color: ${textLight};
  &:hover:not(:disabled) {
    background-color: ${chroma(primaryColor).brighten(0.5).hex()};
  }
  &:active:not(:disabled) {
    background-color: ${chroma(primaryColor).darken(0.5).hex()};
  }
`;

const LinkButton = styled.button`
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: ${linkColor};
  cursor: pointer;
`;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { children, buttonStyle = "default", ...restProps },
  ref
) {
  if (buttonStyle === "link") {
    return (
      <LinkButton {...restProps} ref={ref}>
        {" "}
        {children}
      </LinkButton>
    );
  }
  if (buttonStyle === "primary") {
    return (
      <PrimaryButton {...restProps} ref={ref}>
        {children}
      </PrimaryButton>
    );
  }
  return (
    <DefaultButton {...restProps} ref={ref}>
      {children}
    </DefaultButton>
  );
});

export default Button;
