import React, { useState } from "react";
import TextAreaFormField from "../../components/TextAreaFormField";
import TextFormField from "../../components/TextFormField";
import { TrailWithLandmarks } from "../../core/Trail";
import {
  PatchTrailRequest,
  PostTrailLandmarkRequest,
} from "../../core/Services";
import Button from "../../components/Button";
import { FormField } from "../../components/SharedStyles";
import TrailCoordinateModal from "./TrailCoordinatesModal";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import TrailLandmarksModal from "./TrailLandmarkModal";
import { Landmark } from "../../core/Landmark";
import EditTrailLandmarksModal from "./EditTrailLandmarkModal";
import { TrailLandmark } from "../../core/TrailLandmark";
import {
  borderColor,
  containerColor,
  primaryColor,
} from "../../components/Theme";
import KabobMenu from "../../components/KabobMenu";

interface TrailInformationProps {
  landmarks: Landmark[];
  selectedTrail: TrailWithLandmarks | undefined;
  saveTrailChanges: (changes: PatchTrailRequest) => void;
  addTrailLandmark: (data: PostTrailLandmarkRequest) => Promise<void>;
  updateTrailLandmark: (
    trailLandmarkId: number,
    updates: EditTrailLandmarkProps
  ) => Promise<void>;
  removeTrailLandmark: (trailLandmarkId: number) => Promise<void>;
}

interface TrailLandmarkWithName extends TrailLandmark {
  name: string;
}

export interface SaveTrailLandmarkProps {
  landmarkId: number;
  coordinateIndex: number;
  insertCoordinate: boolean;
  distanceToNextClockwise: string;
  distanceToNextClockwiseDescription: string;
  distanceToNextCounterClockwise: string;
  distanceToNextCounterClockwiseDescription: string;
}

export interface EditTrailLandmarkProps {
  distanceToNextClockwise: string;
  distanceToNextClockwiseDescription: string;
  distanceToNextCounterClockwise: string;
  distanceToNextCounterClockwiseDescription: string;
}

const LandmarkList = styled.div`
  border: 1px solid ${borderColor};
  margin-top: 1rem;
`;

const LandmarkRow = styled.div`
  background-color: ${containerColor};
  border-bottom: 1px solid ${borderColor};
  display: flex;
  padding: 1rem;

  &:last-child {
    border-bottom: none;
  }
  .name {
    flex-grow: 1;
  }
  .icon-button {
    cursor: pointer;
    margin-left: 0.5rem;
    width: 1rem;
    flex-grow: 0;
    flex-shrink: 0;
    &:hover {
      color: ${primaryColor};
    }
  }
`;

export default function TrailInformation({
  selectedTrail,
  addTrailLandmark,
  removeTrailLandmark,
  updateTrailLandmark,
  landmarks,
  saveTrailChanges,
}: TrailInformationProps) {
  const [coordinatesModalVisible, setCoordinatesModalVisible] = useState(false);
  const [landmarkModalVisible, setLandmarkModalVisible] = useState(false);
  const [editLandmarkModalVisible, setEditLandmarkModalVisible] =
    useState(false);
  const [selectedLandmark, setSelectedLandmark] = useState<TrailLandmark>();
  function saveCoordinates(changes: PatchTrailRequest | undefined) {
    setCoordinatesModalVisible(false);
    if (typeof changes !== "undefined") {
      saveTrailChanges(changes);
    }
  }

  async function editLandmark(result?: EditTrailLandmarkProps) {
    setEditLandmarkModalVisible(false);
    if (!selectedLandmark || !result) {
      return;
    }
    await updateTrailLandmark(selectedLandmark.id, result);
  }

  async function saveLandmark(result?: SaveTrailLandmarkProps) {
    setLandmarkModalVisible(false);
    if (typeof selectedTrail === "undefined") {
      throw new Error("selectedTrail should be defined");
    }
    if (typeof result === "undefined") {
      return;
    }

    await addTrailLandmark({
      landmarkId: result.landmarkId,
      coordinateIndex: result.coordinateIndex,
      insertCoordinate: result.insertCoordinate,
      distanceToNextClockwise: result.distanceToNextClockwise,
      distanceToNextClockwiseDescription:
        result.distanceToNextClockwiseDescription,
      distanceToNextCounterClockwise: result.distanceToNextCounterClockwise,
      distanceToNextCounterClockwiseDescription:
        result.distanceToNextCounterClockwiseDescription,
    });
  }

  if (typeof selectedTrail === "undefined") {
    return null;
  }

  const usedLandmarks: TrailLandmarkWithName[] = [];
  const unusedLandmarks: Landmark[] = [];
  landmarks.forEach((landmark) => {
    const trailLandmark = selectedTrail.landmarks.find(
      (trailLandmark) => trailLandmark.landmarkId === landmark.id
    );
    if (trailLandmark) {
      usedLandmarks.push({
        ...trailLandmark,
        name: landmark.name,
      });
    } else if (
      typeof landmark.longitude !== "undefined" &&
      typeof landmark.latitude !== "undefined"
    ) {
      unusedLandmarks.push(landmark);
    }
  });

  const landmarkRows = usedLandmarks.map((landmark) => {
    return (
      <LandmarkRow key={landmark.id}>
        <div className="name">{landmark.name}</div>
        <div
          className="icon-button"
          onClick={(e) => {
            e.preventDefault();
            setSelectedLandmark(landmark);
            setEditLandmarkModalVisible(true);
          }}
        >
          <FontAwesomeIcon icon={faPencil} />
        </div>
        <div className="icon-button">
          <KabobMenu
            menuIcon={faTrashAlt}
            menuItems={[
              {
                onClick: () => {
                  void removeTrailLandmark(landmark.id);
                },
                label: "Delete landmark",
              },
            ]}
          ></KabobMenu>
        </div>
      </LandmarkRow>
    );
  });

  return (
    <>
      <h2>Trail details</h2>
      <TrailCoordinateModal
        onClose={saveCoordinates}
        visible={coordinatesModalVisible}
      />
      <TrailLandmarksModal
        landmarks={unusedLandmarks}
        trail={selectedTrail}
        onClose={saveLandmark}
        visible={landmarkModalVisible}
      />
      <EditTrailLandmarksModal
        trailLandmark={selectedLandmark}
        visible={editLandmarkModalVisible}
        onClose={editLandmark}
      />
      <TextFormField
        id="name"
        type="text"
        label="Name"
        value={selectedTrail.name}
        onChange={(e) => {
          saveTrailChanges({ name: e.target.value });
        }}
      />
      <FormField>
        <label>Trail coordinates</label>
        <Button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setCoordinatesModalVisible(true);
          }}
        >
          Upload GPX File
        </Button>
      </FormField>
      <TextAreaFormField
        id="trailDistanceDescription"
        label="Trail distance description"
        value={selectedTrail.trailDistanceDescription}
        onChange={(e) => {
          saveTrailChanges({ trailDistanceDescription: e.target.value });
        }}
      />
      <TextAreaFormField
        id="longDescription"
        label="Full description"
        value={selectedTrail.longDescription}
        onChange={(e) => {
          saveTrailChanges({ longDescription: e.target.value });
        }}
      />
      <TextAreaFormField
        id="shortDescription"
        label="Simplified description"
        value={selectedTrail.shortDescription}
        onChange={(e) => {
          saveTrailChanges({ shortDescription: e.target.value });
        }}
      />
      <TextFormField
        id="url"
        type="text"
        label="Trail head photo URL"
        value={selectedTrail.imageUrl}
        onChange={(e) => {
          saveTrailChanges({ imageUrl: e.target.value });
        }}
      />
      <TextFormField
        id="altText"
        type="text"
        label="Trail head photo alt text"
        value={selectedTrail.imageAltText}
        onChange={(e) => {
          saveTrailChanges({ imageAltText: e.target.value });
        }}
      />
      <TextFormField
        id="beaconId"
        type="number"
        min="1"
        step="1"
        label="Trail head beacon ID"
        value={selectedTrail.beaconId ?? ""}
        onChange={(e) => {
          saveTrailChanges({ beaconId: Number(e.target.value) });
        }}
      />
      <FormField>
        <label>Landmarks</label>
        <Button
          type="button"
          disabled={
            selectedTrail.coordinates.length === 0 ||
            unusedLandmarks.length === 0
          }
          onClick={(e) => {
            e.preventDefault();
            setLandmarkModalVisible(true);
          }}
        >
          Add landmark
        </Button>
        <LandmarkList>{landmarkRows}</LandmarkList>
      </FormField>
    </>
  );
}
