import React, { useMemo, useState, useRef } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import {
  latLngBounds,
  LatLngExpression,
  marker,
  Map,
  Marker,
  Polyline,
  polyline,
  Icon,
} from "leaflet";
import "leaflet/dist/leaflet.css";
import Coordinate from "../../core/Coordinate";
import { Landmark, LandmarkCategory } from "../../core/Landmark";
import { trailColor } from "../../components/Theme";

interface TrailCoordinatesMapProps {
  loop: boolean;
  trailCoordinates: Coordinate[];
  landmark: Landmark | undefined;
  pointIndex: number;
}

export default function TrailLandmarkMap({
  loop,
  pointIndex,
  trailCoordinates,
  landmark,
}: TrailCoordinatesMapProps) {
  const [map, setMap] = useState<Map | null>(null);
  const boundsSet = useRef(false);
  const line = useRef<Polyline | undefined>();
  const dashedLine = useRef<Polyline | undefined>();
  const trailPoint = useRef<Marker | undefined>();
  const landmarkPoint = useRef<Marker | undefined>();

  if (map) {
    const coordinates = trailCoordinates.map((coordinate: Coordinate) => {
      return [coordinate.latitude, coordinate.longitude] as LatLngExpression;
    });

    let lineCoordinates: LatLngExpression[] = coordinates;
    if (loop) {
      lineCoordinates = [...coordinates, coordinates[0]];
    }
    if (!boundsSet.current) {
      const bounds = latLngBounds(coordinates).pad(0.1);
      map.fitBounds(bounds);
      boundsSet.current = true;
    }
    if (pointIndex < 0 || pointIndex >= lineCoordinates.length) {
      throw new Error("pointIndex out of range");
    }
    let dashPositions: LatLngExpression[];
    let linePositions: LatLngExpression[];
    if (pointIndex === 0) {
      dashPositions = lineCoordinates;
      linePositions = [];
    } else if (pointIndex === lineCoordinates.length - 1) {
      dashPositions = [];
      linePositions = lineCoordinates;
    } else {
      linePositions = lineCoordinates.slice(0, pointIndex + 1);
      dashPositions = lineCoordinates.slice(pointIndex);
    }

    if (typeof line.current === "undefined") {
      const newLine = polyline(linePositions, {
        interactive: false,
        color: trailColor,
      });
      line.current = newLine;
      map.addLayer(newLine);
    } else {
      const polyline = line.current;
      polyline.setLatLngs(linePositions);
    }

    if (typeof dashedLine.current === "undefined") {
      const newDashedLine = polyline(dashPositions, {
        color: trailColor,
        interactive: false,
        dashArray: "4",
      });
      dashedLine.current = newDashedLine;
      map.addLayer(newDashedLine);
    } else {
      const polyline = dashedLine.current;
      polyline.setLatLngs(dashPositions);
    }

    if (typeof trailPoint.current === "undefined") {
      const newMarker = marker(lineCoordinates[pointIndex], {
        interactive: false,
        icon: new Icon({
          iconUrl: `${process.env.PUBLIC_URL}/images/marker-icon.png`,
          shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
          iconAnchor: [12, 43],
        }),
      });
      trailPoint.current = newMarker;
      map.addLayer(newMarker);
    } else {
      trailPoint.current.setLatLng(lineCoordinates[pointIndex]);
    }

    if (
      landmark &&
      typeof landmark.latitude !== "undefined" &&
      typeof landmark.longitude !== "undefined"
    ) {
      const landmarkPosition: LatLngExpression = [
        landmark.latitude,
        landmark.longitude,
      ];
      if (typeof landmarkPoint.current === "undefined") {
        const newMarker = marker(landmarkPosition, {
          interactive: false,
          icon: new Icon({
            iconUrl: `${process.env.PUBLIC_URL}/images/point-of-interest-icon.png`,
            shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
            iconAnchor: [12, 43],
          }),
        });
        landmarkPoint.current = newMarker;
        map.addLayer(newMarker);
      } else {
        landmarkPoint.current.setIcon(
          new Icon({
            iconUrl:
              landmark.category === LandmarkCategory.PointOfInterest
                ? `${process.env.PUBLIC_URL}/images/point-of-interest-icon.png`
                : `${process.env.PUBLIC_URL}/images/building-icon.png`,
            shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
            iconAnchor: [12, 43],
          })
        );
        landmarkPoint.current.setLatLng(landmarkPosition);
      }
    }
  }

  const displayMap = useMemo(() => {
    return (
      <MapContainer style={{ height: "300px" }} ref={setMap}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    );
  }, []);

  return displayMap;
}
