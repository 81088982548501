import React from "react";
import TwoPanelEditorPage from "./TwoPanelEditorPage";
import TextFormField from "../../components/TextFormField";
import BoundaryPicker from "./BoundaryPicker";
import { PatchParkRequest } from "../../core/Services";
import Park from "../../core/Park";

interface AppMetadataProps {
  park: Park | undefined;
  saveParkChanges: (data: PatchParkRequest) => void;
}

function LeftPanel({ park, saveParkChanges }: AppMetadataProps) {
  return (
    <>
      <h2>App metadata</h2>
      <TextFormField
        id="url"
        type="text"
        label="Splash screen graphic URL"
        defaultValue={park?.splashScreenGraphicUrl}
        onChange={(e) => {
          saveParkChanges({ splashScreenGraphicUrl: e.target.value });
        }}
      />
      <TextFormField
        id="appIcon"
        type="text"
        label="App icon URL"
        defaultValue={park?.appIconUrl}
        onChange={(e) => {
          saveParkChanges({ appIconUrl: e.target.value });
        }}
      />
      <TextFormField
        id="beaconUuid"
        type="text"
        label="Beacon UUID"
        defaultValue={park?.beaconUUID}
        onChange={(e) => {
          saveParkChanges({ beaconUUID: e.target.value });
        }}
      />
      <TextFormField
        id="beaconCode"
        type="text"
        label=" Beacon major code"
        defaultValue={park?.beaconMajorCode}
        onChange={(e) => {
          saveParkChanges({ beaconMajorCode: e.target.value });
        }}
      />
    </>
  );
}

function RightPanel({ park, saveParkChanges }: AppMetadataProps) {
  return (
    <>
      <h2>Set park boundary</h2>
      <p>
        Move the map to the location of the park.
        <br />
        This will be used for the default view of the park in the app.
      </p>
      <BoundaryPicker park={park} saveParkChanges={saveParkChanges} />
    </>
  );
}

export default function AppMetadata(props: AppMetadataProps) {
  return (
    <TwoPanelEditorPage
      leftPanel={<LeftPanel {...props} />}
      rightPanel={<RightPanel {...props} />}
    ></TwoPanelEditorPage>
  );
}
