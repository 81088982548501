import chroma from "chroma-js";

export const primaryColor = "#5E7A22";
export const goldColor = "#F7AD25";
export const greenColor = "#6B9416";
export const orangeColor = "#ED6124";
export const errorColor = '#D12F24';
export const trailColor = "#4E63CE";

export const linkColor = primaryColor;
export const textColor = "#1F2515";
export const textLight = "white";
export const textMuted = "#707369";
export const borderColor = "#707369";
export const shadowColor = "#1F2515";
export const hoverColor = chroma(primaryColor).brighten(2.5).desaturate(2).hex();
export const pageColor = "white";
export const containerColor = "white";
export const altColor = "#C1C3BE";
export const disabledColor = "#5A5E53";

export const backgroundColor = "#F8FAF5";

export const normalWeight = 400;
export const boldWeight = 600;
