import { TrailCoordinate } from "./Coordinate";
import { TrailLandmark } from "./TrailLandmark";

export type TrailTypeType = "Loop" | "OutAndBack";

export const TrailType = {
  Loop: "Loop" as TrailTypeType,
  OutAndBack: "OutAndBack" as TrailTypeType,
};

export interface Trail {
  id: number;
  name: string;
  trailType: TrailTypeType;
  trailDistanceDescription: string;
  shortDescription: string;
  longDescription: string;
  imageUrl: string;
  imageAltText: string;
  beaconId: number | undefined;
  coordinates: TrailCoordinate[];
}

export interface TrailWithLandmarks extends Trail {
  landmarks: TrailLandmark[];
}

export interface TrailHead {
  name: string;
  longDescription: string;
  shortDescription: string;
  imageUrl: string;
  imageAltText: string;
}
