import React from "react";
import { useAuth } from "./AuthProvider";
import { useLocation, Navigate } from "react-router-dom";

export default function RequireNoAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (auth.user) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children;
}
