import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button";
import {
  boldWeight,
  primaryColor,
  textLight,
  textMuted,
} from "../../components/Theme";
import chroma from "chroma-js";

interface EditorNavProps {
  returnToDashboard: () => Promise<void>;
  parkLastUpdated: string | undefined;
  saving: boolean;
}

const BackLink = styled(Button)`
  color: ${textLight};
  font-weight: ${boldWeight};
  text-decoration: none;
  padding: 0.5rem 1rem;
  &:hover {
    text-decoration: underline;
  }
`;

const NavBar = styled.nav`
  height: 3rem;
  padding: 0 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: ${primaryColor};
  border-bottom: 1px solid ${textMuted};
`;

const TimeStamp = styled.span`
  color: ${chroma(primaryColor).desaturate(2).brighten(2.5).hex()};
`;

export default function EditorNav({
  saving,
  parkLastUpdated,
  returnToDashboard,
}: EditorNavProps) {
  let lastUpdated = "";
  if (typeof parkLastUpdated !== "undefined") {
    const date = new Date(parkLastUpdated);
    const dateString = date.toLocaleDateString();
    if (dateString !== new Date().toLocaleDateString()) {
      lastUpdated = dateString + " ";
    }
    lastUpdated += new Date(parkLastUpdated).toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
  }

  return (
    <NavBar>
      <BackLink
        buttonStyle="link"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void returnToDashboard();
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} /> Return to dashboard
      </BackLink>{" "}
      <TimeStamp title={`Last updated: ${lastUpdated}`}>
        {saving ? "Saving..." : "All changes saved"}
      </TimeStamp>
    </NavBar>
  );
}
