import React, { useState, MouseEvent, useEffect, useRef } from "react";
import styled from "styled-components";

import Button from "../../components/Button";
import { TrailType, TrailWithLandmarks } from "../../core/Trail";
import Modal from "../../components/Modal";
import TrailLandmarkMap from "./TrailLandmarkMap";
import RangeFormField from "../../components/RangeFormField";
import SelectFormField from "../../components/SelectFormField";
import { Landmark } from "../../core/Landmark";
import { Option } from "../../components/SelectInput";
import TextFormField from "../../components/TextFormField";
import { Checkbox } from "../../components/SharedStyles";
import { SaveTrailLandmarkProps } from "./TrailInformation";
import { borderColor } from "../../components/Theme";

interface TrailCoordinateModalProps {
  trail: TrailWithLandmarks;
  landmarks: Landmark[];
  visible: boolean;
  errorMessage?: string;
  onClose: (value?: SaveTrailLandmarkProps) => Promise<void>;
}

const ButtonRow = styled.div`
  border-top: 1px solid ${borderColor};
  padding-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ModalLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;

  h3 {
    margin-top: 0;
  }
`;

export default function TrailLandmarksModal({
  trail,
  visible = false,
  landmarks,
  onClose,
}: TrailCoordinateModalProps) {
  const [errorText, setErrorText] = useState("");
  const [selectedLandmarkId, setSelectedLandmarkId] = useState<number>();
  const [pointIndex, setPointIndex] = useState(0);
  const [insertCoordinate, setInsertCoordinate] = useState(false);

  const clockwiseDistanceField = useRef<HTMLInputElement>(null);
  const clockwiseDirectionsField = useRef<HTMLInputElement>(null);
  const counterClockwiseDistanceField = useRef<HTMLInputElement>(null);
  const counterClockwiseDirectionsField = useRef<HTMLInputElement>(null);

  let newCoordinates = trail.coordinates;
  const validLandmarks = landmarks.filter((landmark) => {
    return (
      typeof landmark.longitude !== "undefined" &&
      typeof landmark.latitude !== "undefined"
    );
  });
  const landmarkOptions: Option[] = validLandmarks.map((landmark) => {
    return {
      name: landmark.name,
      value: landmark.id,
    };
  });

  const selectedLandmark = validLandmarks.find(
    (landmark) => landmark.id === selectedLandmarkId
  );

  function resetModal() {
    setSelectedLandmarkId(undefined);
    setPointIndex(0);
    setInsertCoordinate(false);
  }

  function onSave(e: MouseEvent) {
    e.preventDefault();

    if (typeof selectedLandmarkId === "undefined") {
      resetModal();
      void onClose();
      return;
    }

    const result = {
      landmarkId: selectedLandmarkId,
      coordinateIndex: pointIndex,
      insertCoordinate: insertCoordinate,
      distanceToNextClockwise: clockwiseDistanceField.current?.value ?? "",
      distanceToNextClockwiseDescription:
        clockwiseDirectionsField.current?.value ?? "",
      distanceToNextCounterClockwise:
        counterClockwiseDistanceField.current?.value ?? "",
      distanceToNextCounterClockwiseDescription:
        counterClockwiseDirectionsField.current?.value ?? "",
    };

    resetModal();
    void onClose(result);
  }

  function onCancel(e: MouseEvent) {
    e.preventDefault();
    resetModal();
    void onClose();
  }

  useEffect(() => {
    let closestPointIndex = 0;
    let closestDistance = Number.MAX_VALUE;

    if (
      newCoordinates.length === 0 ||
      typeof selectedLandmark === "undefined" ||
      typeof selectedLandmark.longitude === "undefined" ||
      typeof selectedLandmark.latitude === "undefined"
    ) {
      return;
    }
    const lat1 = selectedLandmark.latitude;
    const lon1 = selectedLandmark.longitude;
    for (let i = 0; i < newCoordinates.length; i++) {
      const lat2 = newCoordinates[i].latitude;
      const lon2 = newCoordinates[i].longitude;
      const x = lon2 - lon1;
      const y = lat2 - lat1;
      const distance = Math.sqrt(x * x + y * y);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestPointIndex = i;
      }
    }
    while (
      newCoordinates[closestPointIndex].trailLandmarkId &&
      closestPointIndex > 0
    ) {
      closestPointIndex--;
    }
    setPointIndex(closestPointIndex);
  }, [selectedLandmark, landmarks, newCoordinates]);

  useEffect(() => {
    if (newCoordinates.length === 0) {
      return;
    }
    const hasLandmark =
      typeof newCoordinates[pointIndex].trailLandmarkId !== "undefined";
    if (hasLandmark && !insertCoordinate) {
      setErrorText(
        'Cannot add a landmark at this coordinate.  It is already being used for another landmark.  \nUse the slider to adjust the trail position, or check the "Insert landmark coordinate" checkbox'
      );
    } else {
      setErrorText("");
    }
  }, [pointIndex, insertCoordinate, newCoordinates]);

  if (
    insertCoordinate &&
    typeof selectedLandmark !== "undefined" &&
    typeof selectedLandmark.longitude !== "undefined" &&
    typeof selectedLandmark.latitude !== "undefined"
  ) {
    newCoordinates = newCoordinates.slice();
    newCoordinates.splice(pointIndex, 0, {
      longitude: selectedLandmark.longitude,
      latitude: selectedLandmark.latitude,
    });
  }

  return (
    <Modal visible={visible} width={850}>
      <h1
        style={{
          borderBottom: `1px solid ${borderColor}`,
          paddingBottom: "0.5rem",
        }}
      >
        Add Landmark to Trail
      </h1>
      <ModalLayout>
        <div>
          <h3>Select landmark</h3>
          <SelectFormField
            label=""
            id="landmarkSelect"
            placeholder="Choose a landmark..."
            options={landmarkOptions}
            value={selectedLandmarkId}
            onChange={(e) => {
              setSelectedLandmarkId(Number(e.target.value));
            }}
          />
        </div>
        <div></div>
        <div>
          <h3>Trail position</h3>
          <TrailLandmarkMap
            landmark={selectedLandmark}
            loop={trail.trailType === TrailType.Loop}
            pointIndex={pointIndex}
            trailCoordinates={newCoordinates}
          />
          <br />
          <Checkbox style={{ marginBottom: "1rem" }}>
            <label>
              <input
                type="checkbox"
                checked={insertCoordinate}
                onChange={() => setInsertCoordinate(!insertCoordinate)}
              />
              Insert landmark coordinate in trail
            </label>
          </Checkbox>
          <RangeFormField
            value={pointIndex}
            min={1}
            max={trail.coordinates.length - 1}
            step={1}
            label="Adjust trail position"
            id="rangeSlider"
            onChange={(e) => {
              setPointIndex(e.target.valueAsNumber);
            }}
          />
        </div>
        <div>
          <h3>Walking directions</h3>
          <TextFormField
            label="Distance to next landmark"
            id="distanceToNextClockwise"
            type="text"
            ref={clockwiseDistanceField}
          />
          <TextFormField
            label="Directions to next landmark"
            id="distanceToNextClockwiseDescription"
            type="text"
            ref={clockwiseDirectionsField}
          />
          <TextFormField
            label="Distance to previous landmark"
            id="distanceToNextCounterClockwise"
            type="text"
            ref={counterClockwiseDistanceField}
          />
          <TextFormField
            label="Directions to previous landmark"
            id="distanceToNextCounterClockwiseDescription"
            type="text"
            ref={counterClockwiseDirectionsField}
          />
        </div>
      </ModalLayout>
      <ButtonRow>
        <Button
          type="button"
          buttonStyle="primary"
          onClick={onSave}
          disabled={typeof selectedLandmark === "undefined" || errorText !== ""}
        >
          Save
        </Button>
        <Button type="button" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonRow>
      <p
        className="error-text"
        style={{ height: "2rem", whiteSpace: "pre-wrap" }}
      >
        {errorText}
      </p>
    </Modal>
  );
}
