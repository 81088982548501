import React from "react";
import styled from "styled-components";
import Park from "../../core/Park";
import KabobMenu, { MenuItemType } from "../../components/KabobMenu";
import Card from "../../components/Card";
import { textColor } from "../../components/Theme";

interface CardProps {
  park: Park;
  onClick: () => void;
  initiateDeletePark: (park: Park) => void;
  initiateRenamePark: (park: Park) => void;
}

const ContentRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5rem;
  align-items: center;
  color: ${textColor};
`;
const ParkName = styled.div`
  padding: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default function ParkCard({
  park,
  onClick,
  initiateDeletePark,
  initiateRenamePark,
}: CardProps) {
  function onDeleteClick() {
    initiateDeletePark(park);
  }

  function onRenameClick() {
    initiateRenamePark(park);
  }

  const menuItems: MenuItemType[] = [
    { onClick: onRenameClick, label: "Rename" },
    { onClick: onDeleteClick, label: "Delete" },
  ];

  return (
    <Card onClick={onClick} backgroundImageUrl={park.imageUrl}>
      <ContentRow>
        <ParkName>{park.name}</ParkName>
        <KabobMenu menuItems={menuItems}></KabobMenu>
      </ContentRow>
    </Card>
  );
}
