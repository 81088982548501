import React, { useMemo, useState, useRef } from "react";
import PhonePreview from "../../components/PhonePreview";
import { MapContainer, TileLayer } from "react-leaflet";
import {
  LatLngBoundsLiteral,
  LatLngExpression,
  Map,
  Marker,
  Polyline,
  polyline,
  marker,
  Icon,
  latLngBounds,
  LayerGroup,
} from "leaflet";
import "leaflet/dist/leaflet.css";
import Coordinate from "../../core/Coordinate";
import { TrailType, TrailWithLandmarks } from "../../core/Trail";
import styled from "styled-components";
import LandmarkPreview from "./LandmarkPreview";
import { Landmark, LandmarkCategory } from "../../core/Landmark";
import { greenColor, textLight, trailColor } from "../../components/Theme";

interface TrailPreviewProps {
  landmarks: Landmark[];
  trail: TrailWithLandmarks | undefined;
}

const defaultBounds: LatLngBoundsLiteral = [
  [40.83251, -74.93774],
  [39.48072, -75.76171],
];

const PreviewWrapper = styled.div`
  display: flex;
  gap: 2rem;
`;

const Banner = styled.div`
  background-color: ${greenColor};
  color: ${textLight};
  height: 40px;
  font-weight: 500;
  font-size: 14pt;
  padding: 0.5rem 1rem;
`;

const DescriptionContainer = styled.div`
  padding: 1rem;
  height: 80px;
  font-size: 10pt;
  overflow-y: auto;
`;
const MapWrapper = styled.div`
  padding: 0 0.5rem;
`;

export default function TrailPreview({ trail, landmarks }: TrailPreviewProps) {
  const [map, setMap] = useState<Map | null>(null);
  const line = useRef<Polyline | undefined>();
  const trailHead = useRef<Marker | undefined>();
  const landmarksLayer = useRef<LayerGroup | undefined>();

  if (trail && map) {
    if (trail.coordinates.length === 0) {
      if (typeof landmarksLayer.current !== "undefined") {
        landmarksLayer.current.clearLayers();
      }
      if (typeof line.current !== "undefined") {
        map.removeLayer(line.current);
        line.current = undefined;
      }
      if (typeof trailHead.current !== "undefined") {
        map.removeLayer(trailHead.current);
        trailHead.current = undefined;
      }
      map.fitBounds(defaultBounds);
    } else {
      const coordinates = trail.coordinates.map((coordinate: Coordinate) => {
        return [coordinate.latitude, coordinate.longitude] as LatLngExpression;
      });

      if (typeof landmarksLayer.current === "undefined") {
        const group = new LayerGroup();
        landmarksLayer.current = group;
        map.addLayer(group);
      }
      landmarksLayer.current.clearLayers();

      let lineCoordinates: LatLngExpression[] = coordinates;
      if (coordinates.length !== 0 && trail.trailType === TrailType.Loop) {
        lineCoordinates = [...coordinates, coordinates[0]];
      }

      if (typeof line.current === "undefined") {
        const newLine = polyline(lineCoordinates, {
          interactive: false,
          color: trailColor,
        });
        line.current = newLine;
        map.addLayer(newLine);
      } else {
        const polyline = line.current;
        polyline.setLatLngs(lineCoordinates);
      }

      if (typeof trailHead.current === "undefined") {
        const newMarker = marker(lineCoordinates[0], {
          interactive: false,
          icon: new Icon({
            iconUrl: `${process.env.PUBLIC_URL}/images/trail-head-icon.png`,
            shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
            iconAnchor: [12, 43],
          }),
        });
        trailHead.current = newMarker;
        map.addLayer(newMarker);
      } else {
        trailHead.current.setLatLng(lineCoordinates[0]);
      }

      trail.landmarks.forEach((trailLandmark) => {
        const landmark = landmarks.find(
          (landmark) => landmark.id === trailLandmark.landmarkId
        );
        if (typeof landmark === "undefined") {
          throw new Error("unexpected trail landmark id");
        }
        landmarksLayer.current?.addLayer(
          marker([landmark.latitude, landmark.longitude] as LatLngExpression, {
            interactive: false,
            icon: new Icon({
              iconUrl:
                landmark.category === LandmarkCategory.PointOfInterest
                  ? `${process.env.PUBLIC_URL}/images/point-of-interest-icon.png`
                  : `${process.env.PUBLIC_URL}/images/building-icon.png`,
              shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
              iconAnchor: [12, 43],
            }),
          })
        );
      });

      const bounds = latLngBounds(coordinates).pad(0.1);
      map.fitBounds(bounds);
    }
  }

  const displayMap = useMemo(() => {
    return (
      <MapContainer
        bounds={defaultBounds}
        style={{ height: `${628 - 200}px` }}
        zoomControl={false}
        dragging={false}
        boxZoom={false}
        doubleClickZoom={false}
        touchZoom={false}
        scrollWheelZoom={false}
        ref={setMap}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    );
  }, []);

  if (typeof trail === "undefined") {
    return null;
  }

  return (
    <>
      <h2>Preview</h2>
      <PreviewWrapper>
        <PhonePreview>
          <Banner>{trail.name}</Banner>
          <DescriptionContainer>
            {trail.trailDistanceDescription}
          </DescriptionContainer>
          <MapWrapper>{displayMap}</MapWrapper>
        </PhonePreview>
        <LandmarkPreview
          landmark={{
            name: trail.name,
            shortDescription: trail.shortDescription,
            longDescription: trail.longDescription,
            imageUrl: trail.imageUrl,
            imageAltText: trail.imageAltText,
          }}
        />
      </PreviewWrapper>
    </>
  );
}
