import React from "react";
import EditorPage from "./ThreePanelEditorPage";
import { Landmark } from "../../core/Landmark";
import { PatchLandmarkRequest } from "../../core/Services";
import LandmarkSelector from "./LandmarkSelector";
import LandmarkInformation from "./LandmarkInformation";
import LandmarkPreview from "./LandmarkPreview";
import Coordinate from "../../core/Coordinate";

interface LandmarksProps {
  landmarks: Landmark[];
  parkBoundary: Coordinate[] | undefined;
  selectedId: number | undefined;
  setSelectedId: (id: number) => void;
  addLandmark: () => Promise<void>;
  deleteLandmark: (id: number) => Promise<void>;
  saveLandmarkChanges: (data: PatchLandmarkRequest) => void;
}

export default function Landmarks({
  landmarks,
  parkBoundary,
  selectedId,
  setSelectedId,
  addLandmark,
  deleteLandmark,
  saveLandmarkChanges,
}: LandmarksProps) {
  const selectedLandmark = landmarks.find(
    (landmark) => landmark.id === selectedId
  );

  return (
    <EditorPage
      leftPanel={
        <LandmarkSelector
          onAddLandmark={addLandmark}
          onDeleteLandmark={deleteLandmark}
          landmarks={landmarks}
          selectedId={selectedId}
          selectLandmarkId={setSelectedId}
        />
      }
      centerPanel={
        <LandmarkInformation
          parkBoundary={parkBoundary}
          selectedLandmark={selectedLandmark}
          saveLandmarkChanges={saveLandmarkChanges}
        />
      }
      rightPanel={
        <>
          <h2>Preview</h2>
          <LandmarkPreview landmark={selectedLandmark} />
        </>
      }
    ></EditorPage>
  );
}
