import styled from "styled-components";
import React, { ReactNode } from "react";
import { containerColor } from "./Theme";

interface PhonePreviewProps {
  children?: ReactNode;
}

const PreviewContainer = styled.div`
  position: relative;
  height: 666px;
  width: 316px;
  border-radius: 33px;
  border: 8px solid black;
  overflow: hidden;
  background-color: ${containerColor};
`;

const TopBump = styled.div`
  height: 22px;
  background-color: black;
`;

const PhonePreviewContent = styled.div`
  height: 644px;
`;

export default function PhonePreview({ children }: PhonePreviewProps) {
  return (
    <PreviewContainer>
      <TopBump />
      <PhonePreviewContent>{children}</PhonePreviewContent>
    </PreviewContainer>
  );
}
