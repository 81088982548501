import styled from "styled-components";
import React, { ReactNode } from "react";
import { borderColor, containerColor } from "./Theme";

interface ModalProps {
  children: ReactNode;
  visible: boolean;
  width?: number;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const ModalContent = styled.div`
  background-color: ${containerColor};
  border-radius: 5px;
  border: 1px solid ${borderColor};
  padding: 1rem 2rem;
  margin: 1rem;
  max-height: calc(100% - 2rem);
  overflow-y: auto;
`;

export default function Modal({
  children,
  visible = false,
  width = 500,
}: ModalProps) {
  if (!visible) {
    return null;
  }
  return (
    <ModalOverlay>
      <ModalContent style={{ width: `${width}px` }}>{children}</ModalContent>
    </ModalOverlay>
  );
}
