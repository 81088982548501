// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function parseErrorMessage(error: any) {
  console.log(error);

  let message;
  if (error instanceof Error) {
    message = error.message;
  } else if (typeof error === "string") {
    message = error;
  } else {
    return "Unexpected error";
  }
  const parsedError = message.match(/[^:]+$/);
  if (parsedError !== null && parsedError.length > 0) {
    return parsedError[0].trim();
  }
  return message;
}
