import React from "react";
import styled from "styled-components";
import Button from "../../components/Button";
import { Trail } from "../../core/Trail";
import SelectBox from "../../components/SelectBox";
import KabobMenu from "../../components/KabobMenu";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

interface TrailSelectorProps {
  onAddTrail: () => Promise<void>;
  trails: Trail[];
  selectedId: number | undefined;
  selectTrailId: (id: number) => void;
  onDeleteTrail: (id: number) => Promise<void>;
}

const NewButton = styled(Button)`
  margin-bottom: 1rem;
`;

export default function TrailSelector({
  trails,
  selectedId,
  selectTrailId,
  onAddTrail,
  onDeleteTrail,
}: TrailSelectorProps) {
  const trailOptions = trails.map((trail) => {
    return {
      name: trail.name,
      value: trail.id,
    };
  });

  return (
    <>
      <h2>Trails</h2>
      <NewButton
        type="button"
        onClick={(e) => {
          e.preventDefault();
          void onAddTrail();
        }}
      >
        New trail
      </NewButton>
      <SelectBox
        options={trailOptions}
        selectedOption={selectedId}
        onSelect={selectTrailId}
        onDelete={onDeleteTrail}
        getMenu={(value) => {
          return (
            <KabobMenu
              menuIcon={faTrashAlt}
              menuItems={[
                {
                  onClick: () => {
                    void onDeleteTrail(value);
                  },
                  label: "Delete trail",
                },
              ]}
            ></KabobMenu>
          );
        }}
      />
    </>
  );
}
