import React, { MouseEvent, FormEvent } from "react";
import styled from "styled-components";

import Button from "../../components/Button";
import Modal from "../../components/Modal";

interface DeleteParkProps {
  parkName: string | undefined;
  errorMessage?: string;
  visible: boolean;
  onClose: ((value: boolean) => void) | ((value: boolean) => Promise<void>);
}

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export default function DeleteParkModal({
  parkName = "",
  visible = false,
  errorMessage = "",
  onClose,
}: DeleteParkProps) {
  function onOkay(e: FormEvent) {
    e.preventDefault();
    void onClose(true);
  }
  function onCancel(e: MouseEvent) {
    e.preventDefault();
    void onClose(false);
  }

  return (
    <Modal visible={visible}>
      <h1>Delete park app?</h1>
      <p>
        Are you sure you want to delete <strong>{parkName}</strong>? Once
        deleted, the park cannot be restored.
      </p>
      <ButtonRow>
        <Button type="button" buttonStyle="primary" onClick={onOkay}>
          Yes, delete the park
        </Button>
        <Button type="button" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonRow>
      <p>{errorMessage}</p>
    </Modal>
  );
}
