import React, { useState } from "react";
import PhonePreview from "../../components/PhonePreview";
import styled from "styled-components";
import { Landmark, LandmarkCategory } from "../../core/Landmark";
import { TrailHead } from "../../core/Trail";
import { Checkbox } from "../../components/SharedStyles";
import { textMuted } from "../../components/Theme";

interface LandmarkPreviewProps {
  landmark: Landmark | TrailHead | undefined;
}

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: cover;
`;

const ImageWrapper = styled.div`
  margin-top: 2.5rem;
  height: 230px;
  overflow: hidden;
`;

const Block = styled.div`
  white-space: pre-wrap;
  margin-bottom: 0.7rem;

  &.small {
    font-size: 10pt;
  }
`;
const Content = styled.div`
  padding: 1rem;
`;
const Category = styled.div`
  color: ${textMuted};
  text-transform: uppercase;
  font-size: 10pt;
`;

const Layout = styled.div`
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  width: "316px";
`;

const CategoryToString = {
  [LandmarkCategory.Building]: "Building",
  [LandmarkCategory.PointOfInterest]: "Point of Interest",
};

export default function LandmarkPreview({ landmark }: LandmarkPreviewProps) {
  const [simplified, setSimplified] = useState(false);

  let category = "";
  if (typeof landmark !== "undefined") {
    if ("category" in landmark) {
      category = CategoryToString[landmark.category];
    } else {
      category = "Trail Head";
    }
  }
  return (
    <Layout>
      <PhonePreview>
        <ImageWrapper>
          <Image src={landmark?.imageUrl} alt={landmark?.imageAltText} />
        </ImageWrapper>
        <Content>
          <Block>
            <Category>{category}</Category>
          </Block>
          <Block>
            <strong>{landmark?.name}</strong>
          </Block>
          <Block className="small">
            {simplified
              ? landmark?.shortDescription
              : landmark?.longDescription}
          </Block>
        </Content>
      </PhonePreview>
      <Checkbox>
        <label>
          <input
            type="checkbox"
            checked={simplified}
            onChange={() => setSimplified(!simplified)}
          />
          Show simplified view
        </label>
      </Checkbox>
    </Layout>
  );
}
