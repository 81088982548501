import React from "react";
import styled from "styled-components";
import Button from "../../components/Button";
import { Landmark } from "../../core/Landmark";
import SelectBox from "../../components/SelectBox";
import KabobMenu from "../../components/KabobMenu";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

interface LandmarkSelectorProps {
  onAddLandmark: () => Promise<void>;
  landmarks: Landmark[];
  selectedId: number | undefined;
  selectLandmarkId: (id: number) => void;
  onDeleteLandmark: (id: number) => Promise<void>;
}

const NewButton = styled(Button)`
  margin-bottom: 1rem;
`;

export default function LandmarkSelector({
  landmarks,
  selectedId,
  selectLandmarkId,
  onAddLandmark,
  onDeleteLandmark,
}: LandmarkSelectorProps) {
  const landmarkOptions = landmarks.map((landmark) => {
    return {
      name: landmark.name,
      value: landmark.id,
    };
  });

  return (
    <>
      <h2>Landmarks</h2>
      <NewButton
        type="button"
        onClick={(e) => {
          e.preventDefault();
          void onAddLandmark();
        }}
      >
        New landmark
      </NewButton>
      <SelectBox
        options={landmarkOptions}
        selectedOption={selectedId}
        onSelect={selectLandmarkId}
        onDelete={onDeleteLandmark}
        getMenu={(value) => {
          return (
            <KabobMenu
              menuIcon={faTrashAlt}
              menuItems={[
                {
                  onClick: () => {
                    void onDeleteLandmark(value);
                  },
                  label: "Delete landmark",
                },
              ]}
            ></KabobMenu>
          );
        }}
      />
    </>
  );
}
