import React, { useRef, useState, MouseEvent, ChangeEvent } from "react";
import styled from "styled-components";

import Button from "../../components/Button";
import RangeFormField from "../../components/RangeFormField";
import SelectFormField from "../../components/SelectFormField";
import { FormField } from "../../components/SharedStyles";
import Coordinate from "../../core/Coordinate";
import parseGPX from "../../core/parseGPX";
import TrailCoordinatesMap from "./TrailCoordinatesMap";
import { PatchTrailRequest } from "../../core/Services";
import { TrailType, TrailTypeType } from "../../core/Trail";
import Modal from "../../components/Modal";
import {
  borderColor,
  containerColor,
  hoverColor,
} from "../../components/Theme";

interface TrailCoordinateModalProps {
  visible: boolean;
  errorMessage?: string;
  onClose: (value?: PatchTrailRequest) => void;
}

const ButtonRow = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Slider = styled(RangeFormField)`
  width: 400px;
  margin-bottom: 0.5rem;
`;

const FileUpload = styled.label`
  input[type="file"] {
    display: none;
  }
  font-weight: 400 !important;
  display: inline-block !important;
  padding: 0.5rem 1rem;
  background-color: ${containerColor};
  border: 1px solid ${borderColor};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${hoverColor};
  }
`;

export default function TrailCoordinateModal({
  visible = false,
  errorMessage = "",
  onClose,
}: TrailCoordinateModalProps) {
  const [trailType, setTrailType] = useState(TrailType.OutAndBack);
  const [granularity, setGranularity] = useState(1);
  const [rawCoordinates, setRawCoordinates] = useState<Coordinate[]>([]);
  const [coordinates, setCoordinates] = useState<Coordinate[]>([]);
  const [errorText, setErrorText] = useState("");

  const fileInput = useRef<HTMLInputElement>(null);

  const typeOptions = [
    { name: "Out and back", value: TrailType.OutAndBack },
    { name: "Loop", value: TrailType.Loop },
  ];

  async function onFileUpload(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    const files = fileInput.current?.files;
    if (files === null || typeof files === "undefined") {
      return;
    }
    try {
      const coordinates = await parseGPX(files[0]);
      setRawCoordinates(coordinates);
      setCoordinates(coordinates);
      setGranularity(Math.ceil(coordinates.length / 12));
    } catch (err) {
      if (err instanceof Error) {
        setErrorText(err.message);
      } else {
        setErrorText("Unable to read gpx data");
      }
    }
  }

  function resetModal() {
    setErrorText("");
    setCoordinates([]);
    setRawCoordinates([]);
    setGranularity(1);
    setTrailType(TrailType.OutAndBack);
  }

  function onSave(e: MouseEvent) {
    e.preventDefault();
    resetModal();
    if (coordinates.length > 0) {
      onClose({
        coordinates: coordinates,
        trailType: trailType,
      });
    } else {
      onClose();
    }
  }

  function onCancel(e: MouseEvent) {
    e.preventDefault();
    resetModal();
    onClose();
  }

  const max = Math.ceil(rawCoordinates.length / 12);

  function updateGranularity(value: number) {
    setGranularity(value);
    if (value === max) {
      setCoordinates(rawCoordinates);
      return;
    }
    const skipCount = max - value + 1;
    const newCoordinates = rawCoordinates.filter((coord, index) => {
      return index % skipCount === 0;
    });
    setCoordinates(newCoordinates);
  }
  if (!visible) {
    return null;
  }

  return (
    <Modal width={630} visible={visible}>
      <h1>Edit Trail Coordinates</h1>
      <FormField>
        <label>Upload a GPX file</label>
        <FileUpload htmlFor="fileUpload">
          Choose file...
          <input
            id="fileUpload"
            type="file"
            accept="application/xml, .gpx"
            ref={fileInput}
            onChange={(e) => void onFileUpload(e)}
          />
        </FileUpload>
      </FormField>
      <SelectFormField
        id="type"
        options={typeOptions}
        label="Trail type"
        value={trailType}
        onChange={(e) => {
          const value = e.target.value as TrailTypeType;
          setTrailType(value);
        }}
      />

      {max > 1 ? (
        <Slider
          label="Granularity"
          id="granularity"
          onChange={(e) => updateGranularity(e.target.valueAsNumber)}
          value={granularity}
          min={1}
          max={max}
          step={1}
        />
      ) : null}
      <TrailCoordinatesMap
        loop={trailType === TrailType.Loop}
        trailCoordinates={coordinates}
      />
      <ButtonRow>
        <Button
          type="button"
          disabled={coordinates.length === 0}
          buttonStyle="primary"
          onClick={onSave}
        >
          Save
        </Button>
        <Button type="button" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonRow>

      <p className="error-text">{errorText === "" ? errorMessage : errorText}</p>
    </Modal>
  );
}
