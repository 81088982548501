export const LandmarkCategory = {
  PointOfInterest: "PointOfInterest" as LandmarkCategoryType,
  Building: "Building" as LandmarkCategoryType,
};

export type LandmarkCategoryType = "PointOfInterest" | "Building";

export interface Landmark {
  id: number;
  name: string;
  category: LandmarkCategoryType;
  longDescription: string;
  shortDescription: string;
  imageUrl: string;
  imageAltText: string;
  beaconId: number | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
}
