import React from "react";
import styled from "styled-components";
import Modal from "../../components/Modal";
import { borderColor } from "../../components/Theme";

interface EditorErrorProps {
  errorMessage: string;
}

const Title = styled.h3`
  margin: 0;
  padding-bottom: 0.4rem;
  text-align: center;
  border-bottom: 1px solid ${borderColor};
`;
const Message = styled.p`
  text-align: center;
`;

export default function EditorError({ errorMessage }: EditorErrorProps) {
  return (
    <Modal visible={errorMessage !== ""}>
      <Title>Unrecoverable error</Title>
      <Message>{errorMessage}</Message>
    </Modal>
  );
}
