import { AuthContextType } from "../components/AuthProvider";
import Coordinate, { TrailCoordinate } from "../core/Coordinate";
import Park from "../core/Park";
import HttpError from "./HttpError";
import { Landmark, LandmarkCategoryType } from "./Landmark";
import { Trail, TrailTypeType } from "./Trail";
import { TrailLandmark } from "./TrailLandmark";

const baseUrl = "https://stsf7gh0q2.execute-api.us-east-1.amazonaws.com";
const clientSecret = "1d2rd817uk6ei81n6p2agmnrjf2ool1i438da58oefpaf9csae8l";
export const accountUrl = `${baseUrl}/account`;
export const apiUrl = `${baseUrl}/api`;
export const myAccountUrl = `${baseUrl}/myAccount`;

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  clientSecret: string;
  phone?: string;
  email: string;
  password: string;
}

export interface SignUpResponse {
  userId: string;
}

export interface SignInResponse {
  AccessToken: string;
  ExpiresIn: number;
  TokenType: string;
  RefreshToken: string;
  IdToken: string;
}

export interface RefreshResponse {
  AccessToken: string;
  ExpiresIn: number;
  TokenType: string;
  IdToken: string;
}

export interface StartForgotPasswordResponse {
  delivery: string;
  destination: string;
}

export interface SignInRequest {
  email: string;
  password: string;
  clientSecret: string;
}

export interface RefreshRequest {
  idToken: string; // Can be expired, just need to pull out the UUID
  refreshToken: string;
  clientSecret: string;
}

export interface ResendConfirmRequest {
  email: string;
  clientSecret: string;
}

export interface ConfirmSignupRequest {
  email: string;
  clientSecret: string;
  confirmationCode: string;
}

export interface CheckEmailRequest {
  email: string;
}

export interface CheckEmailResponse {
  email: string;
  emailFound: boolean;
  verified?: boolean;
}

export interface StartForgotPasswordRequest {
  email: string;
  clientSecret: string;
}

export interface FinishForgotPasswordRequest {
  email: string;
  clientSecret: string;
  confirmationCode: string;
  newPassword: string;
}

export interface ChangePasswordRequest {
  accessToken: string;
  oldPassword: string;
  newPassword: string;
}

export interface ChangeNameRequest {
  accessToken: string;
  newFirstName: string;
  newLastName: string;
}

export interface ChangeEmailRequest {
  accessToken: string;
  newEmail: string;
}

export interface ConfirmChangeEmailRequest {
  accessToken: string;
  confirmationCode: string;
}

export interface DeleteMyAccountRequest {
  accessToken: string;
}

export interface PostResponse {
  id: number;
}

export interface PostTrailLandmarkResponse {
  id: number;
  coordinates: TrailCoordinate[];
}

export interface PatchParkRequest {
  name?: string;
  address?: string;
  description?: string;
  imageUrl?: string;
  imageAltText?: string;
  appIconUrl?: string;
  splashScreenGraphicUrl?: string;
  boundary?: Coordinate[];
  beaconUUID?: string;
  beaconMajorCode?: string;
}

export interface PatchLandmarkRequest {
  name?: string;
  category?: LandmarkCategoryType;
  longDescription?: string;
  shortDescription?: string;
  imageUrl?: string;
  imageAltText?: string;
  beaconId?: number;
  latitude?: number;
  longitude?: number;
}

export interface PatchTrailRequest {
  name?: string;
  trailType?: TrailTypeType;
  longDescription?: string;
  shortDescription?: string;
  trailDistanceDescription?: string;
  imageUrl?: string;
  imageAltText?: string;
  beaconId?: number;
  coordinates?: Coordinate[];
}

export interface PostTrailLandmarkRequest {
  landmarkId: number;
  coordinateIndex: number;
  insertCoordinate: boolean;
  distanceToNextClockwise: string;
  distanceToNextCounterClockwise: string;
  distanceToNextClockwiseDescription: string;
  distanceToNextCounterClockwiseDescription: string;
}

export interface DeleteTrailLandmarkResponse {
  coordinates: TrailCoordinate[];
}

export interface PatchTrailLandmarkRequest {
  distanceToNextClockwise: string;
  distanceToNextCounterClockwise: string;
  distanceToNextClockwiseDescription: string;
  distanceToNextCounterClockwiseDescription: string;
}

type Headers = Record<string, unknown>;
type PostBody = Record<string, unknown>;

interface FetchOptions {
  url: string;
  headers?: Headers;
  data?: PostBody;
}

async function fetchJson(
  method: "GET" | "POST" | "PATCH" | "DELETE",
  { url, headers = {}, data }: FetchOptions
): Promise<unknown> {
  const body = typeof data !== "undefined" ? JSON.stringify(data) : undefined;
  const response = await fetch(url, {
    method,
    headers: {
      ...headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    mode: "cors",
    body,
  });
  if (!response.ok) {
    const message = await response.text();
    console.log(response.status);
    throw new HttpError(message, response.status);
  }
  if (response.status !== 204) {
    return response.json();
  }
}

async function getJson(options: FetchOptions): Promise<unknown> {
  return fetchJson("GET", options);
}

async function postJson(options: FetchOptions): Promise<unknown> {
  return fetchJson("POST", options);
}

async function deleteJson(options: FetchOptions): Promise<unknown> {
  return fetchJson("DELETE", options);
}

async function patchJson(options: FetchOptions): Promise<unknown> {
  return fetchJson("PATCH", options);
}

async function withAuthorization(
  apiCall: (fetchOptions: FetchOptions) => Promise<unknown>,
  auth: AuthContextType,
  url: string,
  data?: PostBody
): Promise<unknown> {
  try {
    const result = await apiCall({
      url,
      headers: {
        Authorization: auth.user?.idToken,
      },
      data,
    });
    return result;
  } catch (err) {
    if (err instanceof HttpError && err.status === 401) {
      // idToken may have expired.  Try refreshing it and making the API call again
      await auth.refresh();
      const result = await apiCall({
        url,
        headers: {
          Authorization: auth.user?.idToken,
        },
        data,
      });
      return result;
    }
    throw err;
  }
}

export async function postSignUp(data: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}): Promise<SignUpResponse> {
  const result = await postJson({
    url: `${accountUrl}/signUp`,
    data: {
      ...data,
      clientSecret,
    },
  });
  return result as SignUpResponse;
}

export async function postSignIn(
  email: string,
  password: string
): Promise<SignInResponse> {
  const result = await postJson({
    url: `${accountUrl}/login`,
    data: {
      email,
      password,
      clientSecret,
    },
  });
  return result as SignInResponse;
}

export async function postConfirmSignUp(
  email: string,
  confirmationCode: string
): Promise<void> {
  await postJson({
    url: `${accountUrl}/confirmSignUp`,
    data: {
      email,
      confirmationCode,
      clientSecret,
    },
  });
}

export async function postResendConfirmationCode(email: string): Promise<void> {
  await postJson({
    url: `${accountUrl}/resendAccountConfirm`,
    data: {
      email,
      clientSecret,
    },
  });
}

export async function postRefresh(
  idToken: string,
  refreshToken: string
): Promise<RefreshResponse> {
  const result = await postJson({
    url: `${accountUrl}/refresh`,
    data: {
      idToken,
      refreshToken,
      clientSecret,
    },
  });
  return result as RefreshResponse;
}

export async function postStartForgotPassword(
  email: string
): Promise<StartForgotPasswordResponse> {
  const result = await postJson({
    url: `${accountUrl}/startForgotPassword`,
    data: {
      email,
      clientSecret,
    },
  });
  return result as StartForgotPasswordResponse;
}

export async function postFinishForgotPassword(
  email: string,
  confirmationCode: string,
  newPassword: string
): Promise<void> {
  await postJson({
    url: `${accountUrl}/finishForgotPassword`,
    data: {
      email,
      confirmationCode,
      newPassword,
      clientSecret,
    },
  });
}

export async function postChangeName(
  auth: AuthContextType,
  newFirstName: string,
  newLastName: string
): Promise<void> {
  await withAuthorization(postJson, auth, `${myAccountUrl}/changeName`, {
    newFirstName,
    newLastName,
  });
}

export async function postChangeEmail(
  auth: AuthContextType,
  newEmail: string
): Promise<void> {
  await withAuthorization(postJson, auth, `${myAccountUrl}/changeEmail`, {
    newEmail,
    accessToken: auth.user?.accessToken,
  });
}

export async function postConfirmNewEmail(
  auth: AuthContextType,
  confirmationCode: string
): Promise<void> {
  await withAuthorization(postJson, auth, `${myAccountUrl}/confirmNewEmail`, {
    confirmationCode,
    accessToken: auth.user?.accessToken,
  });
}

export async function postChangePassword(
  auth: AuthContextType,
  oldPassword: string,
  newPassword: string
): Promise<void> {
  await withAuthorization(postJson, auth, `${myAccountUrl}/changePassword`, {
    oldPassword,
    newPassword,
    accessToken: auth.user?.accessToken,
  });
}

export async function getParks(auth: AuthContextType): Promise<Park[]> {
  const result = await withAuthorization(getJson, auth, `${apiUrl}/parks`);
  return result as Park[];
}

export async function getPark(
  auth: AuthContextType,
  parkId: number
): Promise<Park> {
  const result = await withAuthorization(
    getJson,
    auth,
    `${apiUrl}/parks/${parkId}`
  );
  return result as Park;
}

export async function postParks(
  auth: AuthContextType,
  name: string
): Promise<PostResponse> {
  const response = (await withAuthorization(postJson, auth, `${apiUrl}/parks`, {
    name,
  })) as { insertId: number };
  return {
    id: response.insertId,
  };
}

export async function deletePark(
  auth: AuthContextType,
  parkId: number
): Promise<void> {
  await withAuthorization(deleteJson, auth, `${apiUrl}/parks/${parkId}`);
}

export async function patchPark(
  auth: AuthContextType,
  parkId: number,
  update: PatchParkRequest
): Promise<void> {
  await withAuthorization(patchJson, auth, `${apiUrl}/parks/${parkId}`, {
    ...update,
  });
}

export async function getLandmarks(
  auth: AuthContextType,
  parkId: number
): Promise<Landmark[]> {
  const result = await withAuthorization(
    getJson,
    auth,
    `${apiUrl}/parks/${parkId}/landmarks`
  );
  return result as Landmark[];
}

export async function postLandmarks(
  auth: AuthContextType,
  parkId: number,
  name: string,
  category: LandmarkCategoryType
): Promise<PostResponse> {
  const response = (await withAuthorization(
    postJson,
    auth,
    `${apiUrl}/parks/${parkId}/landmarks`,
    {
      name,
      category,
    }
  )) as { insertId: number };
  return {
    id: response.insertId,
  };
}

export async function patchLandmark(
  auth: AuthContextType,
  parkId: number,
  landmarkId: number,
  update: PatchLandmarkRequest
): Promise<void> {
  await withAuthorization(
    patchJson,
    auth,
    `${apiUrl}/parks/${parkId}/landmarks/${landmarkId}`,
    {
      ...update,
    }
  );
}

export async function deleteLandmark(
  auth: AuthContextType,
  parkId: number,
  landmarkId: number
): Promise<void> {
  await withAuthorization(
    deleteJson,
    auth,
    `${apiUrl}/parks/${parkId}/landmarks/${landmarkId}`
  );
}

export async function getTrails(
  auth: AuthContextType,
  parkId: number
): Promise<Trail[]> {
  const result = await withAuthorization(
    getJson,
    auth,
    `${apiUrl}/parks/${parkId}/trails`
  );
  return result as Trail[];
}

export async function postTrails(
  auth: AuthContextType,
  parkId: number,
  name: string,
  trailType: TrailTypeType
): Promise<PostResponse> {
  const response = (await withAuthorization(
    postJson,
    auth,
    `${apiUrl}/parks/${parkId}/trails`,
    {
      name,
      trailType,
    }
  )) as { insertId: number };
  return {
    id: response.insertId,
  };
}

export async function patchTrail(
  auth: AuthContextType,
  parkId: number,
  trailId: number,
  update: PatchTrailRequest
): Promise<void> {
  await withAuthorization(
    patchJson,
    auth,
    `${apiUrl}/parks/${parkId}/trails/${trailId}`,
    {
      ...update,
    }
  );
}

export async function deleteTrail(
  auth: AuthContextType,
  parkId: number,
  trailId: number
): Promise<void> {
  await withAuthorization(
    deleteJson,
    auth,
    `${apiUrl}/parks/${parkId}/trails/${trailId}`
  );
}

export async function getTrailLandmarks(
  auth: AuthContextType,
  parkId: number,
  trailId: number
): Promise<TrailLandmark[]> {
  const result = await withAuthorization(
    getJson,
    auth,
    `${apiUrl}/parks/${parkId}/trails/${trailId}/landmarks`
  );
  return result as TrailLandmark[];
}

export async function postTrailLandmarks(
  auth: AuthContextType,
  parkId: number,
  trailId: number,
  data: PostTrailLandmarkRequest
): Promise<PostTrailLandmarkResponse> {
  const response = (await withAuthorization(
    postJson,
    auth,
    `${apiUrl}/parks/${parkId}/trails/${trailId}/landmarks`,
    { ...data }
  )) as { insertId: number; coordinates: TrailCoordinate[] };
  return {
    id: response.insertId,
    coordinates: response.coordinates,
  };
}

export async function deleteTrailLandmark(
  auth: AuthContextType,
  parkId: number,
  trailId: number,
  trailLandmarkId: number
): Promise<DeleteTrailLandmarkResponse> {
  const response = (await withAuthorization(
    deleteJson,
    auth,
    `${apiUrl}/parks/${parkId}/trails/${trailId}/landmarks/${trailLandmarkId}`
  )) as DeleteTrailLandmarkResponse;
  return response;
}

export async function patchTrailLandmark(
  auth: AuthContextType,
  parkId: number,
  trailId: number,
  trailLandmarkId: number,
  data: PatchTrailLandmarkRequest
): Promise<DeleteTrailLandmarkResponse> {
  const response = (await withAuthorization(
    patchJson,
    auth,
    `${apiUrl}/parks/${parkId}/trails/${trailId}/landmarks/${trailLandmarkId}`,
    { ...data }
  )) as DeleteTrailLandmarkResponse;
  return response;
}

interface NominatimAddress {
  city: string;
  state_district: string;
  state: string;
  "ISO3166-2-lvl4": string;
  postcode: string;
  country: string;
  country_code: string;
}

export interface NominatimResult {
  place_id: string;
  licence: string;
  osm_type: string;
  osm_id: string;
  boundingbox: [string, string, string, string];
  lat: string;
  lon: string;
  display_name: string;
  class: string;
  type: string;
  importance: number;
  icon: string;
  address: NominatimAddress;
}

export async function geocode(
  searchText: string
): Promise<NominatimResult | undefined> {
  const result = (await getJson({
    url: `https://nominatim.openstreetmap.org/search?q=${searchText}&format=json`,
  })) as NominatimResult[];
  return result[0];
}
