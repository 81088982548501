import React, { useRef, useState, FormEvent } from "react";
import Button from "../components/Button";
import TextFormField from "../components/TextFormField";
import { useNavigate } from "react-router-dom";
import {
  emailSchema,
  passwordSchema,
  confirmationCodeSchema,
} from "../core/Validation";
import parseErrorMessage from "../core/parseErrorMessage";
import { useAuth } from "../components/AuthProvider";
import {
  postFinishForgotPassword,
  postStartForgotPassword,
} from "../core/Services";

export default function ResetPassword() {
  const [errorText, setErrorText] = useState("");
  const [showCodeForm, setShowCodeForm] = useState(false);
  const [email, setEmail] = useState("");

  const auth = useAuth();
  const navigate = useNavigate();

  const emailField = useRef<HTMLInputElement>(null);
  const codeField = useRef<HTMLInputElement>(null);
  const passwordField = useRef<HTMLInputElement>(null);

  async function sendResetEmail(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setErrorText("");
    try {
      const email = emailSchema.validateSync(emailField.current?.value ?? "");
      await postStartForgotPassword(email);
      setEmail(email);
      setShowCodeForm(true);
    } catch (err) {
      setErrorText(parseErrorMessage(err));
    }
  }

  async function saveNewPassword(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      const password = passwordSchema.validateSync(
        passwordField.current?.value ?? ""
      );
      const code = confirmationCodeSchema.validateSync(
        codeField.current?.value ?? ""
      );
      await postFinishForgotPassword(email, code, password);
      await auth.signIn(email, password);
      navigate("/dashboard");
    } catch (err) {
      setErrorText(parseErrorMessage(err));
    }
  }
  if (!showCodeForm) {
    return (
      <>
        <h1>Reset password</h1>
        <p>We'll send instructions to your email to reset your password.</p>
        <form onSubmit={(e) => void sendResetEmail(e)}>
          <TextFormField
            key="email"
            id="email"
            label="Email"
            type="text"
            ref={emailField}
          />
          <Button type="submit">Send email</Button>
        </form>
        <p className="error-text">{errorText}</p>
      </>
    );
  }

  return (
    <>
      <h1>Set new password</h1>
      <p>
        A 6-digit code has been sent to <strong>{email}</strong>. Please enter
        it below and set your new password:
      </p>
      <form onSubmit={(e) => void saveNewPassword(e)}>
        <TextFormField
          key="code"
          id="code"
          label="Confirmation code"
          type="text"
          name="ConfirmationCode"
          ref={codeField}
        />
        <TextFormField
          key="password"
          id="password"
          label="New password"
          name="password"
          type="password"
          ref={passwordField}
        />
        <Button type="submit">Update password</Button>
      </form>
      <p className="error-text">{errorText}</p>
    </>
  );
}
