import { forwardRef } from "react";
import { FormField } from "./SharedStyles";
import TextArea from "./TextArea";

type TextAreaFormFieldProps = JSX.IntrinsicElements["textarea"] & {
  label?: string;
  id: string;
};

const TextAreaFormField = forwardRef<
  HTMLTextAreaElement,
  TextAreaFormFieldProps
>(function TextAreaFormField({ label, id, ...restProps }, ref) {
  return (
    <FormField>
      {typeof label !== "undefined" && <label htmlFor={id}>{label}</label>}
      <TextArea {...restProps} ref={ref} id={id} />
    </FormField>
  );
});

export default TextAreaFormField;
