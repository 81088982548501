import chroma from "chroma-js";
import { ReactNode, MouseEventHandler } from "react";
import styled from "styled-components";
import { borderColor, containerColor, primaryColor } from "./Theme";

interface CardProps {
  children?: ReactNode;
  backgroundImageUrl: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const CardContainer = styled.div`
  display: inline-block;
  width: 202px;
  background-color: ${containerColor};
  border: 1px solid ${borderColor};
`;
const CardImageWrapper = styled.div`
  width: 200px;
  height: 200px;
  background-color: ${chroma(primaryColor).desaturate(2).brighten(2).hex()};
  background-size: cover;
  transition: opacity ease-in-out 0.2s;
  &.clickable {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const CardContent = styled.div`
  border-top: 1px solid ${borderColor};
`;

export default function Card({
  backgroundImageUrl,
  children,
  onClick,
}: CardProps) {
  const classes = typeof onClick === "function" ? "clickable" : "";

  return (
    <CardContainer>
      <CardImageWrapper
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        className={classes}
        onClick={onClick}
      ></CardImageWrapper>
      <CardContent>{children}</CardContent>
    </CardContainer>
  );
}
