import styled from "styled-components";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export default function LoadingScreen() {
  return (
    <Container>
      <FontAwesomeIcon spinPulse icon={faSpinner} size="2x" />
      <div className="visually-hidden">Loading...</div>
    </Container>
  );
}
