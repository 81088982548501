import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../components/Card";
import ParkCard from "./dashboard/ParkCard";
import { useNavigate } from "react-router-dom";
import { ResponsiveContainer } from "../components/SharedStyles";
import Park from "../core/Park";
import { deletePark, getParks, patchPark, postParks } from "../core/Services";
import { useAuth } from "../components/AuthProvider";
import NewParkModal from "./dashboard/NewParkModal";
import DeleteParkModal from "./dashboard/DeleteParkModal";
import RenameParkModal from "./dashboard/RenameParkModal";
import LoadingScreen from "../components/LoadingScreen";

const CardContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
`;

const FirstCardText = styled.div`
  padding: 0.5rem;
`;

export default function Dashboard() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [selectedPark, setSelectedPark] = useState<Park>();
  const [loading, setLoading] = useState(true);
  const [parks, setParks] = useState<Park[]>([]);
  const [newParkModalVisible, setNewParkModalVisible] = useState(false);
  const [newParkError, setNewParkError] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteError, setDeleteError] = useState("");
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [renameError, setRenameError] = useState("");

  useEffect(() => {
    void (async () => {
      if (loading) {
        try {
          const result = await getParks(auth);
          setParks(result);
        } finally {
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  function openEditor(parkId: number) {
    navigate(`/editor/${parkId}`);
  }

  function initiateDeletePark(park: Park) {
    setSelectedPark(park);
    setDeleteModalVisible(true);
  }

  function initiateRenamePark(park: Park) {
    setSelectedPark(park);
    setRenameModalVisible(true);
  }

  async function deleteApp(confirm: boolean) {
    if (typeof selectedPark === "undefined") {
      throw new Error("selectedPark should be defined");
    }
    setDeleteError("");
    if (!confirm) {
      setDeleteModalVisible(false);
      return;
    }
    try {
      await deletePark(auth, selectedPark.id);
      setParks(parks.filter((park) => park.id !== selectedPark.id));
      setDeleteModalVisible(false);
    } catch (err) {
      console.log(err);
      setDeleteError("Unexpected error");
    }
  }

  async function renameApp(parkName?: string): Promise<void> {
    if (typeof selectedPark === "undefined") {
      throw new Error("selectedPark should be defined");
    }
    setRenameError("");
    if (typeof parkName === "undefined" || parkName === selectedPark.name) {
      setRenameModalVisible(false);
      return;
    }
    try {
      await patchPark(auth, selectedPark.id, { name: parkName });
      const updatedParks = parks.map((park) => {
        if (park.id === selectedPark.id) {
          return {
            ...park,
            name: parkName,
          };
        }
        return park;
      });
      setParks(updatedParks);
    } catch (err) {
      console.log(err);
      setRenameError("Unexpected error");
    }
    setRenameModalVisible(false);
  }

  async function createNewApp(parkName?: string): Promise<void> {
    setNewParkError("");
    if (typeof parkName === "undefined") {
      setNewParkModalVisible(false);
      return;
    }
    try {
      const { id } = await postParks(auth, parkName);
      navigate(`/editor/${id}`);
    } catch (err) {
      console.log(err);
      setNewParkError("Unexpected error");
    }
  }

  const parkCards = parks.map((park) => {
    return (
      <ParkCard
        onClick={() => {
          openEditor(park.id);
        }}
        initiateDeletePark={initiateDeletePark}
        initiateRenamePark={initiateRenamePark}
        park={park}
        key={park.id}
      ></ParkCard>
    );
  });

  return (
    <>
      <NewParkModal
        visible={newParkModalVisible}
        onClose={createNewApp}
        errorMessage={newParkError}
      />
      <DeleteParkModal
        parkName={selectedPark?.name}
        visible={deleteModalVisible}
        onClose={deleteApp}
        errorMessage={deleteError}
      />
      <RenameParkModal
        visible={renameModalVisible}
        onClose={renameApp}
        parkName={selectedPark?.name ?? ""}
        errorMessage={renameError}
      />
      <ResponsiveContainer>
        <h1>Dashboard</h1>
        {loading ? (
          <LoadingScreen />
        ) : (
          <CardContainer>
            <Card
              backgroundImageUrl={`${process.env.PUBLIC_URL}/images/add-graphic.png`}
              onClick={(e) => {
                e.preventDefault();
                setNewParkModalVisible(true);
              }}
            >
              <FirstCardText>Create new app...</FirstCardText>
            </Card>
            {parkCards}
          </CardContainer>
        )}
      </ResponsiveContainer>
    </>
  );
}
