import chroma from "chroma-js";
import { ReactNode } from "react";
import styled from "styled-components";
import {
  borderColor,
  containerColor,
  hoverColor,
  primaryColor,
  textLight,
} from "./Theme";

export interface Option {
  name: string;
  value: number;
}
interface SelectBoxProps {
  options: Option[];
  selectedOption: number | undefined;
  onSelect: (value: number) => void;
  onDelete: (value: number) => Promise<void>;
  getMenu?: (value: number) => ReactNode;
}

const SelectBoxContainer = styled.div`
  border: 1px solid ${borderColor};
  background-color: ${containerColor};
`;

const SelectRow = styled.div`
  border-bottom: 1px solid ${borderColor};
  display: flex;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
  padding: 1rem;
  &:hover {
    background-color: ${hoverColor};
  }
  &.selected {
    background-color: ${chroma(primaryColor)
      .brighten(0.5)
      .desaturate(0.5)
      .hex()};
    color: ${textLight};
    svg {
      color: ${textLight};
      fill: ${textLight};
      stroke: ${textLight};
    }
  }
  .name {
    flex-grow: 1;
  }
  .menu {
    width: 1rem;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

function SelectBox({
  options,
  onSelect,
  selectedOption,
  getMenu,
}: SelectBoxProps) {
  if (options.length === 0) {
    return null;
  }

  const optionsElements = options.map(({ name, value }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return (
      <SelectRow
        key={value}
        role="option"
        className={selectedOption === value ? "selected" : ""}
        aria-selected={selectedOption === value}
        onClick={() => {
          onSelect(value);
        }}
      >
        <div className="name">{name}</div>
        {getMenu ? <div className="menu">{getMenu(value)}</div> : null}
      </SelectRow>
    );
  });
  return (
    <SelectBoxContainer role="listbox">{optionsElements}</SelectBoxContainer>
  );
}

export default SelectBox;
