import React, { forwardRef } from "react";
import { FormField } from "./SharedStyles";
import RangeInput from "./RangeInput";

type RangeFormFieldProps = JSX.IntrinsicElements["input"] & {
  id: string;
  label?: string;
};

const RangeFormField = forwardRef<HTMLInputElement, RangeFormFieldProps>(
  function RangeFormField({ id, label, className, ...restProps }, ref) {
    return (
      <FormField className={className}>
        {typeof label !== "undefined" && <label htmlFor={id}>{label}</label>}
        <RangeInput {...restProps} id={id} ref={ref} />
      </FormField>
    );
  }
);

export default RangeFormField;
