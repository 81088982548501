import React from "react";
import styled from "styled-components";
import { ResponsiveContainer } from "../components/SharedStyles";
import { borderColor, containerColor } from "../components/Theme";
import AccountSettings from "./auth/AccountSettings";
import ChangePassword from "./auth/ChangePassword";

const Layout = styled.div`
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
`;

const Section = styled.div`
  border: 1px solid ${borderColor};
  border-radius: 5px;
  padding: 1rem 2rem 3rem;
  flex-shrink: 0;
  width: 400px;
  background-color: ${containerColor};
`;

export default function Account() {
  return (
    <ResponsiveContainer>
      <h1>My account</h1>
      <Layout>
        <Section>
          <AccountSettings />
        </Section>
        <Section>
          <ChangePassword />
        </Section>
      </Layout>
    </ResponsiveContainer>
  );
}
