import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import RequireNoAuth from "../../components/RequireNoAuth";

const FormWrapper = styled.div`
  max-width: 350px;
  margin: 0 auto;
`;

export default function AuthenticationLayout() {
  return (
    <FormWrapper>
      <RequireNoAuth>
        <Outlet />
      </RequireNoAuth>
    </FormWrapper>
  );
}
