import { forwardRef } from "react";
import { FormField } from "./SharedStyles";
import SelectInput, { Option } from "./SelectInput";

type SelectFormFieldProps = JSX.IntrinsicElements["select"] & {
  id: string;
  options: Option[];
  placeholder?: string;
  label?: string;
};

const SelectFormField = forwardRef<HTMLSelectElement, SelectFormFieldProps>(
  function SelectFormField({ id, label, options, ...restProps }, ref) {
    return (
      <FormField>
        {typeof label !== "undefined" && <label htmlFor={id}>{label}</label>}
        <SelectInput {...restProps} id={id} options={options} ref={ref} />
      </FormField>
    );
  }
);

export default SelectFormField;
