import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0 1rem;
`;

export default function ParkInformation() {
  return (
    <Wrapper>
      <h2>Export...</h2>
    </Wrapper>
  );
}
