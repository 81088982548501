import React, { useMemo, useState, useRef } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import {
  latLngBounds,
  LatLngBoundsLiteral,
  Map,
  Polyline,
  polyline,
} from "leaflet";
import "leaflet/dist/leaflet.css";
import Coordinate from "../../core/Coordinate";
import { trailColor } from "../../components/Theme";

interface TrailCoordinatesMapProps {
  loop: boolean;
  trailCoordinates: Coordinate[] | undefined;
}

const defaultBounds: LatLngBoundsLiteral = [
  [40.83251, -74.93774],
  [39.48072, -75.76171],
];

export default function TrailCoordinatesMap({
  loop,
  trailCoordinates,
}: TrailCoordinatesMapProps) {
  const [map, setMap] = useState<Map | null>(null);
  const line = useRef<Polyline | undefined>();

  let coordinates = defaultBounds;

  if (map) {
    if (line.current) {
      map.removeLayer(line.current);
    }
    if (
      typeof trailCoordinates !== "undefined" &&
      trailCoordinates.length > 0
    ) {
      coordinates = trailCoordinates.map((coordinate: Coordinate) => {
        return [coordinate.latitude, coordinate.longitude];
      });

      let lineCoordinates = coordinates;
      if (loop) {
        lineCoordinates = [...coordinates, coordinates[0]];
      }
      const newLine = polyline(lineCoordinates, {
        color: trailColor,
        interactive: false,
      });
      line.current = newLine;
      map.addLayer(newLine);
    }

    const bounds = latLngBounds(coordinates).pad(0.1);
    map.fitBounds(bounds);
  }

  const displayMap = useMemo(() => {
    return (
      <MapContainer
        bounds={defaultBounds}
        style={{ height: "300px" }}
        zoomControl={false}
        dragging={false}
        boxZoom={false}
        doubleClickZoom={false}
        touchZoom={false}
        scrollWheelZoom={false}
        ref={setMap}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    );
  }, []);

  return displayMap;
}
