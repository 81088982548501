import React, { FormEvent, useRef, useState } from "react";
import TextFormField from "../../components/TextFormField";
import Button from "../../components/Button";
import { useAuth } from "../../components/AuthProvider";
import { postChangePassword } from "../../core/Services";
import parseErrorMessage from "../../core/parseErrorMessage";
import { passwordSchema } from "../../core/Validation";

export default function ChangePassword() {
  const auth = useAuth();
  const user = auth.user;

  const [errorText, setErrorText] = useState("");

  const oldPasswordField = useRef<HTMLInputElement>(null);
  const newPasswordField = useRef<HTMLInputElement>(null);

  async function changePassword(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setErrorText("");
    if (typeof user === "undefined") {
      throw new Error("Unrecognized user");
    }

    let oldPassword;
    try {
      oldPassword = passwordSchema.validateSync(
        oldPasswordField.current?.value ?? ""
      );
    } catch (err) {
      setErrorText("Current password is incorrect");
      return;
    }
    try {
      const newPassword = passwordSchema.validateSync(
        newPasswordField.current?.value ?? ""
      );
      await postChangePassword(auth, oldPassword, newPassword);
      setErrorText("Password changed");
    } catch (err) {
      if (
        err instanceof Error &&
        err.message.includes("NotAuthorizedException")
      ) {
        setErrorText("Current password is incorrect");
      } else {
        setErrorText(parseErrorMessage(err));
      }
    }
  }

  return (
    <div>
      <h2>Change password</h2>
      <form onSubmit={(e) => void changePassword(e)}>
        <TextFormField
          label="Current password"
          type="password"
          id="password"
          name="password"
          ref={oldPasswordField}
        />
        <TextFormField
          label="New password"
          type="password"
          id="newPassword"
          name="newPassword"
          ref={newPasswordField}
        />

        <Button type="submit" buttonStyle="primary">Change password</Button>
      </form>
      <p className="error-text">{errorText}</p>
    </div>
  );
}
