import { ReactNode } from "react";
import styled from "styled-components";
import {
  EditorPageContainer,
  EditorPagePanel,
} from "../../components/SharedStyles";

interface EditorPageProps {
  leftPanel: ReactNode;
  rightPanel: ReactNode;
}

const Layout = styled.div`
  display: grid;
  grid-template-columns: 500px 1fr;
  height: 100%;
`;

export default function EditorPage({ leftPanel, rightPanel }: EditorPageProps) {
  return (
    <EditorPageContainer>
      <Layout>
        <EditorPagePanel>{leftPanel}</EditorPagePanel>
        <EditorPagePanel>{rightPanel}</EditorPagePanel>
      </Layout>
    </EditorPageContainer>
  );
}
