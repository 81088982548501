import React, { forwardRef } from "react";
import styled from "styled-components";
import { borderColor } from "./Theme";

type TextInputProps = JSX.IntrinsicElements["input"] & {
  type: "text" | "password" | "number";
};

const StyledInput = styled.input`
  display: block;
  padding: 0.5rem 1rem;
  width: 100%;
  border: 1px solid ${borderColor};
  border-radius: 5px;
`;

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(props, ref) {
    return <StyledInput {...props} ref={ref} />;
  }
);

export default TextInput;
