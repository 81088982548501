import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../components/AuthProvider";
import Button from "../../components/Button";
import { ResponsiveContainer } from "../../components/SharedStyles";
import {
  boldWeight,
  borderColor,
  primaryColor,
  textLight,
} from "../../components/Theme";

const NavBar = styled.nav`
  padding: 0.5rem 3rem;
  background-color: ${primaryColor};
  border-bottom: 1px solid ${borderColor};
  a {
    color: ${textLight};
    font-weight: ${boldWeight};
    text-decoration: none;
    padding: 0.5rem 1rem;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const SignOutButton = styled(Button)`
  color: ${textLight};
  font-weight: ${boldWeight};
  text-decoration: none;
  padding: 0.5rem 1rem;
  &:hover {
    text-decoration: underline;
  }
`;

const NavContents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function Nav() {
  const auth = useAuth();
  const navigate = useNavigate();

  function signOut() {
    auth.signOut();
    navigate("/sign-in");
  }

  if (!auth.user) {
    return <></>;
  }

  return (
    <NavBar>
      <ResponsiveContainer>
        <NavContents>
          <div>
            <Link to={"/"}>Home</Link>{" "}
            {/* {auth.user ? `Welcome ${auth.user.email}` : ""} */}
          </div>
          <div>
            <Link to={"/account"}>My account</Link>
            <SignOutButton type="button" buttonStyle="link" onClick={signOut}>
              Sign out
            </SignOutButton>
          </div>
        </NavContents>
      </ResponsiveContainer>
    </NavBar>
  );
}
