import React, { FormEvent, MouseEvent, useRef, useState } from "react";
import styled from "styled-components";

import TextFormField from "../../components/TextFormField";
import Button from "../../components/Button";
import { postResendConfirmationCode } from "../../core/Services";
import parseErrorMessage from "../../core/parseErrorMessage";
import { confirmationCodeSchema } from "../../core/Validation";

interface ConfirmationCodeFormProps {
  email: string;
  submitCode: (code: string) => Promise<void>;
}

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
`;

export default function ConfirmationCodeForm({
  email,
  submitCode,
}: ConfirmationCodeFormProps) {
  const [showCodeSentText, setShowCodeSentText] = useState(false);
  const [errorText, setErrorText] = useState("");

  const codeField = useRef<HTMLInputElement>(null);

  async function resendCode(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    try {
      await postResendConfirmationCode(email);
      setShowCodeSentText(true);
    } catch (err) {
      setErrorText("Unexpected Error, retry resending code");
      console.log(err);
    }
  }

  async function confirmSignUp(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setErrorText("");
    try {
      const code = confirmationCodeSchema.validateSync(
        codeField.current?.value ?? ""
      );
      await submitCode(code);
    } catch (err) {
      setErrorText(parseErrorMessage(err));
    }
  }

  return (
    <>
      <h1>Confirm email</h1>
      <p>
        A 6-digit code has been sent to <strong>{email}</strong>. Please enter
        it below:
      </p>
      <form
        onSubmit={(e) => {
          void confirmSignUp(e);
        }}
      >
        <TextFormField
          id="code"
          label="Confirmation code"
          type="text"
          name="ConfirmationCode"
          ref={codeField}
        />
        <ButtonRow>
          <Button type="submit">Confirm</Button>
          <Button
            type="button"
            buttonStyle="link"
            onClick={(e) => {
              void resendCode(e);
            }}
          >
            Resend code
          </Button>
          {showCodeSentText ? "Code sent" : ""}
        </ButtonRow>
      </form>
      <p className="error-text">{errorText}</p>
    </>
  );
}
