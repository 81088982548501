import React, { FormEvent, useRef, useState } from "react";
import Button from "../components/Button";
import TextFormField from "../components/TextFormField";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { postSignUp } from "../core/Services";
import parseErrorMessage from "../core/parseErrorMessage";
import { object } from "yup";
import ConfirmationCodeForm from "./auth/ConfirmationCodeForm";
import {
  emailSchema,
  passwordSchema,
  firstNameSchema,
  lastNameSchema,
} from "../core/Validation";
import { useAuth } from "../components/AuthProvider";
import { postConfirmSignUp } from "../core/Services";

const SplitRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const Separator = styled.hr`
  margin: 1.5rem 0;
`;

const signUpSchema = object({
  email: emailSchema,
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  password: passwordSchema,
});

export default function SignUp() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [showCodeForm, setShowCodeForm] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");

  const firstNameField = useRef<HTMLInputElement>(null);
  const lastNameField = useRef<HTMLInputElement>(null);
  const emailField = useRef<HTMLInputElement>(null);
  const passwordField = useRef<HTMLInputElement>(null);

  async function signUp(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setErrorText("");
    try {
      const data = signUpSchema.validateSync({
        firstName: firstNameField.current?.value ?? "",
        lastName: lastNameField.current?.value ?? "",
        email: emailField.current?.value ?? "",
        password: passwordField.current?.value ?? "",
      });
      await postSignUp(data);
      setEmail(data.email);
      setPassword(data.password);
      setShowCodeForm(true);
    } catch (err) {
      setErrorText(parseErrorMessage(err));
    }
  }

  async function submitCode(code: string) {
    await postConfirmSignUp(email, code);
    await auth.signIn(email, password);
    navigate("/dashboard");
  }

  if (showCodeForm) {
    return <ConfirmationCodeForm email={email} submitCode={submitCode} />;
  }

  return (
    <>
      <div>
        <h1>Sign up</h1>
        <form onSubmit={(e) => void signUp(e)}>
          <SplitRow>
            <TextFormField
              id="firstname"
              label="First name"
              name="FirstName"
              type="text"
              ref={firstNameField}
            />
            <TextFormField
              id="lastname"
              label="Last name"
              name="LastName"
              type="text"
              ref={lastNameField}
            />
          </SplitRow>
          <TextFormField
            id="email"
            label="Email"
            type="text"
            name="email"
            ref={emailField}
          />
          <TextFormField
            id="password"
            label="Password"
            name="password"
            type="password"
            ref={passwordField}
          />
          <Button type="submit">Sign up</Button>
        </form>
        <p className="error-text">{errorText}</p>
      </div>
      <Separator />
      <p>
        Already have an account? <Link to="/sign-in">Sign in</Link>
      </p>
    </>
  );
}
