import Coordinate from "./Coordinate";

const gpxErrorText = "Unable to parse document";

export default async function parseGPX(file: File): Promise<Coordinate[]> {
  const fileText: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function () {
          reject(reader.error);
        };
      } catch (err) {
        reject(err);
      }
    }
  );
  if (typeof fileText !== "string") {
    throw new Error(gpxErrorText);
  }

  const parser = new DOMParser();
  const gpx = parser.parseFromString(fileText, "application/xml");
  const root = gpx.documentElement;
  if (root.tagName === "parsererror") {
    //firefox
    throw new Error(root.firstChild?.nodeValue ?? "Unable to parse document");
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (gpx.body) {
    //chrome
    throw new Error(gpx.body.innerText);
  }
  const tracks = gpx.querySelectorAll("trk");
  if (tracks.length === 0) {
    throw new Error(gpxErrorText);
  }
  const coordinates: Coordinate[] = [];
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < tracks.length; i++) {
    const track = tracks[i];
    const trackPoints = track.querySelectorAll("trkpt");
    if (trackPoints.length === 0) {
      continue;
    }
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let j = 0; j < trackPoints.length; j++) {
      const trackPoint = trackPoints[j];
      const latValue = trackPoint.getAttribute("lat");
      const lonValue = trackPoint.getAttribute("lon");
      if (latValue === null || lonValue === null) {
        throw new Error(gpxErrorText);
      }
      const latitude = parseFloat(latValue);
      const longitude = parseFloat(lonValue);
      coordinates.push({
        longitude,
        latitude,
      });
    }
  }
  if (coordinates.length === 0) {
    throw new Error(gpxErrorText);
  }
  return coordinates;
}
