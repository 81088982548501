import React from "react";
import EditorPage from "./ThreePanelEditorPage";
import { TrailWithLandmarks } from "../../core/Trail";
import {
  PatchTrailRequest,
  PostTrailLandmarkRequest,
} from "../../core/Services";
import TrailSelector from "./TrailSelector";
import TrailInformation, { EditTrailLandmarkProps } from "./TrailInformation";
import TrailPreview from "./TrailPreview";
import { Landmark } from "../../core/Landmark";

interface TrailsProps {
  trails: TrailWithLandmarks[];
  landmarks: Landmark[];
  selectedId: number | undefined;
  setSelectedId: (id: number) => void;
  addTrailLandmark: (data: PostTrailLandmarkRequest) => Promise<void>;
  removeTrailLandmark: (trailLandmarkId: number) => Promise<void>;
  updateTrailLandmark: (
    trailLandmarkId: number,
    updates: EditTrailLandmarkProps
  ) => Promise<void>;
  addTrail: () => Promise<void>;
  deleteTrail: (id: number) => Promise<void>;
  saveTrailChanges: (data: PatchTrailRequest) => void;
}

export default function Trails({
  trails,
  landmarks,
  selectedId,
  setSelectedId,
  addTrail,
  deleteTrail,
  saveTrailChanges,
  addTrailLandmark,
  removeTrailLandmark,
  updateTrailLandmark,
}: TrailsProps) {
  const selectedTrail = trails.find((trail) => trail.id === selectedId);

  return (
    <EditorPage
      leftPanel={
        <TrailSelector
          onAddTrail={addTrail}
          onDeleteTrail={deleteTrail}
          trails={trails}
          selectedId={selectedId}
          selectTrailId={setSelectedId}
        />
      }
      centerPanel={
        <TrailInformation
          landmarks={landmarks}
          selectedTrail={selectedTrail}
          saveTrailChanges={saveTrailChanges}
          addTrailLandmark={addTrailLandmark}
          removeTrailLandmark={removeTrailLandmark}
          updateTrailLandmark={updateTrailLandmark}
        />
      }
      rightPanel={<TrailPreview trail={selectedTrail} landmarks={landmarks} />}
    ></EditorPage>
  );
}
