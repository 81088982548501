import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Normalize } from "styled-normalize";
import styled, { ThemeProvider } from "styled-components";
import { createTheme } from "styled-breakpoints";

import Dashboard from "./routes/Dashboard";
import SignIn from "./routes/SignIn";
import SignUp from "./routes/SignUp";
import NotFound from "./routes/NotFound";
import Editor from "./routes/Editor";
import { breakpoints } from "./components/SharedStyles";
import ResetPassword from "./routes/ResetPassword";
import { AuthProvider } from "./components/AuthProvider";
import RequireAuth from "./components/RequireAuth";
import DashboardLayout from "./routes/dashboard/DashboardLayout";
import Account from "./routes/Account";
import AuthenticationLayout from "./routes/auth/AuthenticationLayout";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
`;

const theme = createTheme(breakpoints);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Normalize />
      <AuthProvider>
        <Wrapper>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
              <Route element={<AuthenticationLayout />}>
                <Route path="/" element={<SignIn />} />
                <Route path="sign-in" element={<SignIn />} />
                <Route path="sign-up" element={<SignUp />} />
                <Route path="reset-password" element={<ResetPassword />} />
              </Route>
              <Route element={<DashboardLayout />}>
                <Route path="account" element={<Account />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route
                path="editor/:parkId"
                element={
                  <RequireAuth>
                    <Editor />
                  </RequireAuth>
                }
              ></Route>
            </Routes>
          </BrowserRouter>
        </Wrapper>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
