import React, { forwardRef } from "react";
import styled from "styled-components";
import { borderColor } from "./Theme";

type RangeInputProps = JSX.IntrinsicElements["input"];

const StyledInput = styled.input`
  display: block;
  padding: 0.5rem 0rem;
  width: 100%;
  border: 1px solid ${borderColor};
  border-radius: 5px;
`;

const RangeInput = forwardRef<HTMLInputElement, RangeInputProps>(
  function RangeInput(props, ref) {
    return <StyledInput {...props} ref={ref} type="range" />;
  }
);

export default RangeInput;
