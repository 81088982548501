import { forwardRef } from "react";
import styled from "styled-components";
import { borderColor } from "./Theme";

export interface Option {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: string | number;
}
type SelectInputProps = JSX.IntrinsicElements["select"] & {
  options: Option[];
  placeholder?: string;
};

const StyledSelect = styled.select`
  display: block;
  padding: 0.5rem 1rem;
  width: 100%;
  border: 1px solid ${borderColor};
  border-radius: 5px;
`;

const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>(
  function SelectInput({ options, placeholder, ...restProps }, ref) {
    const optionsElements = options.map((option) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      );
    });
    return (
      <StyledSelect {...restProps} ref={ref}>
        {placeholder ? (
          <option value={-1} disabled selected>
            {placeholder}
          </option>
        ) : null}
        {optionsElements}
      </StyledSelect>
    );
  }
);

export default SelectInput;
