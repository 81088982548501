import React, { MouseEvent, useRef } from "react";
import styled from "styled-components";

import Button from "../../components/Button";
import Modal from "../../components/Modal";
import TextFormField from "../../components/TextFormField";
import { EditTrailLandmarkProps } from "./TrailInformation";
import { TrailLandmark } from "../../core/TrailLandmark";
import { borderColor } from "../../components/Theme";

interface TrailLandmarkModalProps {
  trailLandmark: TrailLandmark | undefined;
  visible: boolean;
  onClose: (value?: EditTrailLandmarkProps) => Promise<void>;
}

const ButtonRow = styled.div`
  border-top: 1px solid ${borderColor};
  padding-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export default function EditTrailLandmarksModal({
  trailLandmark,
  visible,
  onClose,
}: TrailLandmarkModalProps) {
  const clockwiseDistanceField = useRef<HTMLInputElement>(null);
  const clockwiseDirectionsField = useRef<HTMLInputElement>(null);
  const counterClockwiseDistanceField = useRef<HTMLInputElement>(null);
  const counterClockwiseDirectionsField = useRef<HTMLInputElement>(null);

  function resetModal() {
    if (clockwiseDistanceField.current) {
      clockwiseDistanceField.current.value = "";
    }
    if (clockwiseDirectionsField.current) {
      clockwiseDirectionsField.current.value = "";
    }
    if (counterClockwiseDistanceField.current) {
      counterClockwiseDistanceField.current.value = "";
    }
    if (counterClockwiseDirectionsField.current) {
      counterClockwiseDirectionsField.current.value = "";
    }
  }

  function onSave(e: MouseEvent) {
    e.preventDefault();

    const result = {
      distanceToNextClockwise: clockwiseDistanceField.current?.value ?? "",
      distanceToNextClockwiseDescription:
        clockwiseDirectionsField.current?.value ?? "",
      distanceToNextCounterClockwise:
        counterClockwiseDistanceField.current?.value ?? "",
      distanceToNextCounterClockwiseDescription:
        counterClockwiseDirectionsField.current?.value ?? "",
    };

    resetModal();
    void onClose(result);
  }

  function onCancel(e: MouseEvent) {
    e.preventDefault();
    resetModal();
    void onClose();
  }

  if (typeof trailLandmark === "undefined") {
    return null;
  }

  return (
    <Modal visible={visible}>
      <h1>Edit Landmark Details</h1>
      <div>
        <h3>Walking directions</h3>
        <TextFormField
          label="Distance to next landmark"
          id="distanceToNextClockwise"
          type="text"
          defaultValue={trailLandmark.distanceToNextClockwise}
          ref={clockwiseDistanceField}
        />
        <TextFormField
          label="Directions to next landmark"
          id="distanceToNextClockwiseDescription"
          type="text"
          defaultValue={trailLandmark.distanceToNextClockwiseDescription}
          ref={clockwiseDirectionsField}
        />
        <TextFormField
          label="Distance to previous landmark"
          id="distanceToNextCounterClockwise"
          type="text"
          defaultValue={trailLandmark.distanceToNextCounterClockwise}
          ref={counterClockwiseDistanceField}
        />
        <TextFormField
          label="Directions to previous landmark"
          id="distanceToNextCounterClockwiseDescription"
          type="text"
          defaultValue={trailLandmark.distanceToNextCounterClockwiseDescription}
          ref={counterClockwiseDirectionsField}
        />
      </div>
      <ButtonRow>
        <Button type="button" buttonStyle="primary" onClick={onSave}>
          Save
        </Button>
        <Button type="button" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonRow>
    </Modal>
  );
}
