import React, { forwardRef } from "react";
import { FormField } from "./SharedStyles";
import TextInput from "./TextInput";

type TextFormFieldProps = JSX.IntrinsicElements["input"] & {
  type: "text" | "password" | "number";
  id: string;
  label?: string;
};

const TextFormField = forwardRef<HTMLInputElement, TextFormFieldProps>(
  function TextFormField({ id, label, className, ...restProps }, ref) {
    return (
      <FormField className={className}>
        {typeof label !== "undefined" && <label htmlFor={id}>{label}</label>}
        <TextInput {...restProps} id={id} ref={ref} />
      </FormField>
    );
  }
);

export default TextFormField;
