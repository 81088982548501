import React, { FormEvent, useRef, useState } from "react";
import styled from "styled-components";
import TextFormField from "../../components/TextFormField";
import Button from "../../components/Button";
import { useAuth } from "../../components/AuthProvider";
import {
  postChangeEmail,
  postChangeName,
  postConfirmNewEmail,
} from "../../core/Services";
import parseErrorMessage from "../../core/parseErrorMessage";
import {
  emailSchema,
  firstNameSchema,
  lastNameSchema,
} from "../../core/Validation";
import ConfirmationCodeForm from "./ConfirmationCodeForm";

const NameSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;
export default function AccountSettings() {
  const auth = useAuth();
  const user = auth.user;

  const [errorText, setErrorText] = useState("");

  const [firstName, setFirstName] = useState(user?.firstName ?? "");
  const [lastName, setLastName] = useState(user?.lastName ?? "");
  const [email, setEmail] = useState(user?.email ?? "");
  const [showConfirmationForm, setShowConfirmationForm] = useState(false);

  const firstNameField = useRef<HTMLInputElement>(null);
  const lastNameField = useRef<HTMLInputElement>(null);
  const emailField = useRef<HTMLInputElement>(null);

  async function saveAccountSettings(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setErrorText("");
    if (typeof user === "undefined") {
      throw new Error("Unrecognized user");
    }

    try {
      let newFirstName = firstNameSchema.validateSync(
        firstNameField.current?.value ?? firstName
      );
      let newLastName = lastNameSchema.validateSync(
        lastNameField.current?.value ?? lastName
      );
      let newEmail = emailSchema.validateSync(
        emailField.current?.value ?? email
      );

      const firstNameChanged = newFirstName !== firstName;
      const lastNameChanged = newLastName !== lastName;
      const emailChanged = newEmail !== email;
      if (emailChanged) {
        newEmail = emailSchema.validateSync(newEmail);
        await postChangeEmail(auth, newEmail);
      }
      if (firstNameChanged || lastNameChanged) {
        newFirstName = firstNameChanged ? newFirstName : firstName;
        newLastName = lastNameChanged ? newLastName : lastName;
        await postChangeName(auth, newFirstName, newLastName);
      }
      setEmail(newEmail);
      setFirstName(newFirstName);
      setLastName(newLastName);
      if (emailChanged) {
        setShowConfirmationForm(true);
      } else {
        setErrorText("Changes saved");
      }
    } catch (err) {
      setErrorText(parseErrorMessage(err));
    }
  }

  async function submitCode(code: string) {
    if (typeof user === "undefined") {
      throw new Error("Unrecognized user");
    }
    await postConfirmNewEmail(auth, code);
    await auth.refresh(); // refresh to get new id token with updated email address
    setShowConfirmationForm(false);
    setErrorText("Changes saved");
  }

  if (showConfirmationForm) {
    return <ConfirmationCodeForm email={email} submitCode={submitCode} />;
  }

  return (
    <div>
      <h2>Account settings</h2>
      <form onSubmit={(e) => void saveAccountSettings(e)}>
        <NameSection>
          <TextFormField
            label="First name"
            type="text"
            id="firstName"
            name="firstName"
            defaultValue={firstName}
            ref={firstNameField}
          />
          <TextFormField
            label="Last name"
            type="text"
            id="lastName"
            name="lastName"
            defaultValue={lastName}
            ref={lastNameField}
          />
        </NameSection>
        <TextFormField
          label="Email"
          type="text"
          id="email"
          name="email"
          defaultValue={email}
          ref={emailField}
        />
        <Button type="submit" buttonStyle="primary">Save changes</Button>
      </form>
      <p className="error-text">{errorText}</p>
    </div>
  );
}
