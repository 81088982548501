import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createGlobalStyle } from "styled-components";
import { backgroundColor, errorColor, textColor } from "./components/Theme";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${backgroundColor};
    color: ${textColor};
    font-size: 16px;
  }

  .error-text {
    color: ${errorColor}
  }
`;

const root = ReactDOM.createRoot(
  // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
);
