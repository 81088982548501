import styled from "styled-components";
import { up } from "styled-breakpoints";
import { boldWeight, borderColor } from "./Theme";

export const breakpoints = {
  xs: "0px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};


export const FormField = styled.div`
  margin-bottom: 1.5rem;

  label {
    font-weight: ${boldWeight};
    margin-bottom: 0.4rem;
    display: block;
  }

  .error {
    text-align: right;
  }
`;

export const Checkbox = styled.div`
  input {
    cursor: pointer;
    margin-right: 5px;
  }
  label {
    cursor: pointer;
    font-weight: ${boldWeight};
  }
`;

export const EditorPageContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const EditorPagePanel = styled.div`
  border-right: 1px solid ${borderColor};
  padding: 0 2rem;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &:last-child {
    border-right: none;
  }
`;

export const ResponsiveContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;

  ${up("md")} {
    max-width: ${breakpoints.md};
  }

  ${up("lg")} {
    max-width: ${breakpoints.lg};
  }

  ${up("xl")} {
    max-width: ${breakpoints.xl};
  }

  ${up("xxl")} {
    max-width: ${breakpoints.xxl};
  }
`;
