import { string } from "yup";

export const emailSchema = string().trim().required().email().label("Email");

export const passwordSchema = string()
  .label("Password")
  .required()
  .min(10)
  .test({
    name: "uppercase",
    exclusive: true,
    message: "Password must contain at least 1 uppercase letter",
    test: (value?: string) =>
      typeof value !== "undefined" && /[A-Z]/.test(value),
  })
  .test({
    name: "lowercase",
    exclusive: true,
    message: "Password must contain at least 1 lowercase letter",
    test: (value?: string) =>
      typeof value !== "undefined" && /[a-z]/.test(value),
  })
  .test({
    name: "digit",
    exclusive: true,
    message: "Password must contain at least 1 number",
    test: (value?: string) => typeof value !== "undefined" && /\d/.test(value),
  });

export const confirmationCodeSchema = string()
  .label("Confirmation code")
  .required()
  .length(6)
  .test({
    name: "digit",
    exclusive: true,
    message: "Invalid confirmation code provided, please try again",
    test: (value?: string) =>
      typeof value !== "undefined" && /^\d\d\d\d\d\d$/.test(value),
  });

export const firstNameSchema = string().trim().required().label("First name");
export const lastNameSchema = string().trim().required().label("Last name");
