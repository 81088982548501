import React, { MouseEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import {
  borderColor,
  containerColor,
  hoverColor,
  primaryColor,
  textColor,
} from "./Theme";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const KabobButton = styled.button`
  text-align: center;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: ${primaryColor};
    fill: ${primaryColor};
  }
`;

const MenuDiv = styled.div`
  position: fixed;
  z-index: 3000;
  background-color: ${containerColor};
  border: 1px solid ${borderColor};
  border-bottom: none;
  color: ${textColor};
`;

const Menu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const MenuItem = styled.li`
  padding: 1rem;
  border-bottom: 1px solid ${borderColor};
  cursor: pointer;
  &:hover {
    background-color: ${hoverColor};
  }
`;

interface KabobProps {
  menuItems: MenuItemType[];
  menuIcon?: IconDefinition;
}

export interface MenuItemType {
  onClick: () => void;
  label: string;
}

export default function KabobMenu({ menuItems, menuIcon }: KabobProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    const onWindowClick: EventListener = (e) => {
      if (!open) {
        return;
      }
      if (
        ref.current === null ||
        !ref.current.contains(e.target as HTMLElement)
      ) {
        e.preventDefault();
        e.stopPropagation();
        setOpen(false);
      }
    };

    window.addEventListener("click", onWindowClick, true);
    return () => {
      window.removeEventListener("click", onWindowClick, true);
    };
  }, [open]);

  function openMenu(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();

    const { top, left } = e.currentTarget.getBoundingClientRect();

    setTop(top + 10);
    setLeft(left + 15);
    setOpen(true);
  }

  const menu = menuItems.map((item) => {
    return (
      <MenuItem
        key={item.label}
        role="menuitem"
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          e.preventDefault();
          item.onClick();
          setOpen(false);
        }}
      >
        {item.label}
      </MenuItem>
    );
  });

  let contextMenu;
  if (open) {
    contextMenu = (
      <MenuDiv style={{ top, left }}>
        <Menu role="menu">{menu}</Menu>
      </MenuDiv>
    );
  } else {
    contextMenu = null;
  }

  const icon: IconDefinition = menuIcon ? menuIcon : faEllipsisV;

  return (
    <Wrapper ref={ref}>
      <KabobButton onClick={openMenu}>
        <span className="visually-hidden">Menu</span>
        <FontAwesomeIcon icon={icon} />
      </KabobButton>
      {contextMenu}
    </Wrapper>
  );
}
