import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import ParkInformation from "./editor/ParkInformation";
import AppMetadata from "./editor/AppMetadata";
import Landmarks from "./editor/Landmarks";
import Trails from "./editor/Trails";
import ExportApp from "./editor/ExportApp";
import EditorNav from "./editor/EditorNav";
import { useAuth } from "../components/AuthProvider";
import Park from "../core/Park";
import { TrailType, TrailWithLandmarks } from "../core/Trail";
import {
  PatchParkRequest,
  getPark,
  getLandmarks,
  getTrails,
  patchPark,
  postLandmarks,
  deleteLandmark,
  deleteTrailLandmark,
  patchLandmark,
  postTrails,
  patchTrail,
  deleteTrail,
  PatchTrailRequest,
  PatchLandmarkRequest,
  getTrailLandmarks,
  PostTrailLandmarkRequest,
  postTrailLandmarks,
  patchTrailLandmark,
} from "../core/Services";
import debounce from "lodash.debounce";
import HttpError from "../core/HttpError";
import EditorError from "./editor/EditorError";
import { Landmark, LandmarkCategory } from "../core/Landmark";
import { TrailLandmark } from "../core/TrailLandmark";
import { EditTrailLandmarkProps } from "./editor/TrailInformation";
import {
  boldWeight,
  borderColor,
  containerColor,
  hoverColor,
  primaryColor,
} from "../components/Theme";
import chroma from "chroma-js";
import LoadingScreen from "../components/LoadingScreen";

const leftNavColor = chroma(primaryColor).brighten(2).desaturate(1.5).hex();
const FillContainer = styled.div`
  width: 100%;
  height: calc(100vh - 3rem);
`;

const EditorLayout = styled.div`
  display: grid;
  grid-template-columns: 16rem 1fr;
  height: 100%;
`;

const GridColumn = styled.div`
  height: 100%;
  overflow: hidden;
  &:first-child {
    border-right: 1px solid ${borderColor};
  }
`;

const NavButton = styled.button`
  display: block;
  width: 100%;
  text-align: left;
  padding: 1rem;
  border: 1px solid ${borderColor};
  border-top: 0;
  border-right: 0;
  cursor: pointer;
  background-color: ${leftNavColor};
  &:hover:not(.active) {
    background-color: ${hoverColor};
  }
  &.active {
    font-weight: ${boldWeight};
    cursor: default;
    background-color: ${containerColor};
  }
`;

const tabs = {
  INFO: "info",
  METADATA: "metadata",
  LANDMARKS: "landmarks",
  TRAILS: "trails",
  EXPORT: "export",
};

export default function Editor() {
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const parkId = Number(params.parkId);

  const pendingParkChanges = useRef({});
  const pendingLandmarkChanges = useRef({});
  const pendingTrailChanges = useRef({});

  const [activeTab, setActiveTab] = useState(tabs.INFO);
  const [park, setPark] = useState<Park>();
  const [lastUpdated, setLastUpdated] = useState("");

  const [landmarks, setLandmarks] = useState<Landmark[]>([]);
  const [selectedLandmarkId, setLandmarkId] = useState<number>();
  const setSelectedLandmarkId = (id: number) => {
    void debouncedLandmarkSave.flush();
    setLandmarkId(id);
  };

  const [trails, setTrails] = useState<TrailWithLandmarks[]>([]);
  const [selectedTrailId, setTrailId] = useState<number>();
  const setSelectedTrailId = (id: number) => {
    void debouncedTrailSave.flush();
    setTrailId(id);
  };

  const [saving, setSaving] = useState(false);
  const [appError, setAppError] = useState("");

  const saveHandler = async (callback: () => Promise<void>) => {
    try {
      setSaving(true);
      await callback();
      setLastUpdated(new Date().toISOString());
    } catch (err) {
      setAppError("Unexpected error when saving changes");
      console.log(err);
    } finally {
      setSaving(false);
    }
  };

  const debouncedParkSave = useCallback(
    debounce(async function () {
      if (Object.keys(pendingParkChanges.current).length === 0) {
        return;
      }
      await saveHandler(async () => {
        const changes = { ...pendingParkChanges.current };
        pendingParkChanges.current = {};
        await patchPark(auth, parkId, changes);
      });
    }, 2000),
    [parkId]
  );

  function saveParkChanges(data: PatchParkRequest) {
    if (typeof park === "undefined") {
      throw new Error("park should be defined");
    }
    pendingParkChanges.current = {
      ...pendingParkChanges.current,
      ...data,
    };
    setPark({
      ...park,
      ...data,
    });
    void debouncedParkSave();
  }

  const debouncedLandmarkSave = useCallback(
    debounce(async function () {
      if (Object.keys(pendingLandmarkChanges.current).length === 0) {
        return;
      }
      await saveHandler(async () => {
        if (typeof selectedLandmarkId === "undefined") {
          return;
        }
        const changes = { ...pendingLandmarkChanges.current };
        pendingLandmarkChanges.current = {};
        await patchLandmark(auth, parkId, selectedLandmarkId, changes);
      });
    }, 2000),
    [parkId, selectedLandmarkId]
  );

  function saveLandmarkChanges(data: PatchLandmarkRequest) {
    const newLandmarks: Landmark[] = landmarks.map((landmark) => {
      if (landmark.id !== selectedLandmarkId) {
        return landmark;
      }
      if (typeof data.longitude !== "undefined" && !isNaN(data.longitude)) {
        data.longitude = Number(Number(data.longitude).toFixed(5));
      }
      if (typeof data.latitude !== "undefined" && !isNaN(data.latitude)) {
        data.latitude = Number(Number(data.latitude).toFixed(5));
      }
      return {
        ...landmark,
        ...data,
      } as Landmark;
    });
    setLandmarks(newLandmarks);

    if (typeof data.name !== "undefined") {
      data.name = data.name.trim();
      if (data.name === "") {
        delete data.name;
      }
    }
    if (typeof data.beaconId !== "undefined") {
      const id = Number(data.beaconId);
      if (isNaN(id) || id < 0 || !Number.isInteger(id)) {
        delete data.beaconId;
      } else {
        data.beaconId = id;
      }
    }
    if (typeof data.longitude !== "undefined") {
      const longitude = Number(data.longitude);
      if (isNaN(longitude) || longitude < -180 || longitude > 180) {
        delete data.longitude;
      }
    }
    if (typeof data.latitude !== "undefined") {
      const latitude = Number(data.latitude);
      if (isNaN(latitude) || latitude < -90 || latitude > 90) {
        delete data.latitude;
      }
    }

    pendingLandmarkChanges.current = {
      ...pendingLandmarkChanges.current,
      ...data,
    };

    void debouncedLandmarkSave();
  }

  async function addLandmark() {
    await saveHandler(async () => {
      const { id } = await postLandmarks(
        auth,
        parkId,
        "Untitled",
        LandmarkCategory.PointOfInterest
      );
      const newLandmark = {
        id,
        name: "Untitled",
        category: LandmarkCategory.PointOfInterest,
        longDescription: "",
        shortDescription: "",
        imageUrl: "",
        imageAltText: "",
        beaconId: undefined,
        latitude: undefined,
        longitude: undefined,
      };
      setLandmarks([...landmarks, newLandmark]);
      setSelectedLandmarkId(id);
    });
  }

  async function removeLandmark(landmarkId: number) {
    if (landmarkId === selectedLandmarkId) {
      cancelLandmarkChanges();
    }
    await saveHandler(async () => {
      await deleteLandmark(auth, parkId, landmarkId);
      const index = landmarks.findIndex((value) => {
        return value.id === landmarkId;
      });
      const newLandmarks = [...landmarks];
      newLandmarks.splice(index, 1);
      setLandmarks(newLandmarks);
      if (selectedLandmarkId === landmarkId) {
        const newSelected =
          newLandmarks[Math.min(index, newLandmarks.length - 1)];
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        setSelectedLandmarkId(newSelected?.id);
      }
      const newTrails = trails.map((trail) => {
        const index = trail.landmarks.findIndex(
          (landmark) => landmark.landmarkId === landmarkId
        );
        if (index === -1) {
          return trail;
        }
        const newLandmarks = [...trail.landmarks];
        newLandmarks.splice(index, 1);
        return {
          ...trail,
          landmarks: newLandmarks,
        };
      });
      setTrails(newTrails);
    });
  }

  function cancelLandmarkChanges() {
    debouncedLandmarkSave.cancel();
  }

  const debouncedTrailSave = useCallback(
    debounce(async function () {
      if (Object.keys(pendingTrailChanges.current).length === 0) {
        return;
      }
      await saveHandler(async () => {
        if (typeof selectedTrailId === "undefined") {
          return;
        }
        const changes = { ...pendingTrailChanges.current };
        pendingTrailChanges.current = {};
        await patchTrail(auth, parkId, selectedTrailId, changes);
      });
    }, 2000),
    [parkId, selectedTrailId]
  );

  function saveTrailChanges(data: PatchTrailRequest) {
    const newTrails: TrailWithLandmarks[] = trails.map((trail) => {
      if (trail.id !== selectedTrailId) {
        return trail;
      }
      return {
        ...trail,
        ...data,
      } as TrailWithLandmarks;
    });
    setTrails(newTrails);

    if (typeof data.name !== "undefined") {
      data.name = data.name.trim();
      if (data.name === "") {
        delete data.name;
      }
    }
    if (typeof data.beaconId !== "undefined") {
      const id = Number(data.beaconId);
      if (isNaN(id) || id < 0 || !Number.isInteger(id)) {
        delete data.beaconId;
      } else {
        data.beaconId = id;
      }
    }

    pendingTrailChanges.current = {
      ...pendingTrailChanges.current,
      ...data,
    };

    void debouncedTrailSave();
  }

  async function addTrail() {
    await saveHandler(async () => {
      const { id } = await postTrails(auth, parkId, "Untitled", TrailType.Loop);
      const newTrail: TrailWithLandmarks = {
        id,
        name: "Untitled",
        trailType: TrailType.Loop,
        trailDistanceDescription: "",
        shortDescription: "",
        longDescription: "",
        imageUrl: "",
        imageAltText: "",
        beaconId: undefined,
        coordinates: [],
        landmarks: [],
      };
      setTrails([...trails, newTrail]);
      setSelectedTrailId(id);
    });
  }

  async function removeTrail(trailId: number) {
    if (trailId === selectedTrailId) {
      cancelTrailChanges();
    }
    await saveHandler(async () => {
      await deleteTrail(auth, parkId, trailId);
      const index = trails.findIndex((value) => {
        return value.id === trailId;
      });
      const newTrails = [...trails];
      newTrails.splice(index, 1);
      setTrails(newTrails);
      if (selectedTrailId === trailId) {
        const newSelected = newTrails[Math.min(index, newTrails.length - 1)];
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        setSelectedTrailId(newSelected?.id);
      }
    });
  }

  async function addTrailLandmark(data: PostTrailLandmarkRequest) {
    if (typeof selectedTrailId === "undefined") {
      throw new Error("selectedTrailId should be defined");
    }
    await saveHandler(async () => {
      const { id, coordinates } = await postTrailLandmarks(
        auth,
        parkId,
        selectedTrailId,
        data
      );
      const newTrailLandmark: TrailLandmark = {
        id,
        trailId: selectedTrailId,
        ...data,
      };
      const newTrails: TrailWithLandmarks[] = trails.map((trail) => {
        if (trail.id !== selectedTrailId) {
          return trail;
        }
        return {
          ...trail,
          landmarks: [...trail.landmarks, newTrailLandmark],
          coordinates,
        } as TrailWithLandmarks;
      });
      setTrails(newTrails);
    });
  }

  async function updateTrailLandmark(
    trailLandmarkId: number,
    updates: EditTrailLandmarkProps
  ) {
    if (typeof selectedTrailId === "undefined") {
      throw new Error("selectedTrailId should be defined");
    }
    await saveHandler(async () => {
      await patchTrailLandmark(
        auth,
        parkId,
        selectedTrailId,
        trailLandmarkId,
        updates
      );

      const newTrails: TrailWithLandmarks[] = trails.map((trail) => {
        if (trail.id !== selectedTrailId) {
          return trail;
        }

        const newLandmarks = trail.landmarks.map((landmark) => {
          if (landmark.id !== trailLandmarkId) {
            return landmark;
          }
          return {
            ...landmark,
            ...updates,
          };
        });

        return {
          ...trail,
          landmarks: newLandmarks,
        } as TrailWithLandmarks;
      });
      setTrails(newTrails);
    });
  }

  async function removeTrailLandmark(trailLandmarkId: number) {
    if (typeof selectedTrailId === "undefined") {
      throw new Error("selectedTrailId should be defined");
    }
    await saveHandler(async () => {
      const { coordinates } = await deleteTrailLandmark(
        auth,
        parkId,
        selectedTrailId,
        trailLandmarkId
      );

      const newTrails: TrailWithLandmarks[] = trails.map((trail) => {
        if (trail.id !== selectedTrailId) {
          return trail;
        }

        const index = trail.landmarks.findIndex((value) => {
          return value.id === trailLandmarkId;
        });
        const newLandmarks = [...trail.landmarks];
        newLandmarks.splice(index, 1);

        return {
          ...trail,
          landmarks: newLandmarks,
          coordinates,
        } as TrailWithLandmarks;
      });
      setTrails(newTrails);
    });
  }

  function cancelTrailChanges() {
    debouncedTrailSave.cancel();
  }

  const immediatelySavePendingChanges = useCallback(
    async function () {
      await debouncedParkSave.flush();
      await debouncedLandmarkSave.flush();
      await debouncedTrailSave.flush();
    },
    [debouncedParkSave, debouncedLandmarkSave, debouncedTrailSave]
  );

  async function returnToDashboard() {
    await immediatelySavePendingChanges();
    navigate("/dashboard");
  }

  useEffect(() => {
    void (async () => {
      if (isNaN(parkId)) {
        navigate("/notfound");
        return;
      }
      try {
        const parkResult = await getPark(auth, parkId);
        setPark(parkResult);
        setLastUpdated(parkResult.lastUpdated);

        const landmarkResult = await getLandmarks(auth, parkId);
        setLandmarks(landmarkResult);
        if (landmarkResult.length > 0) {
          setSelectedLandmarkId(landmarkResult[0].id);
        }

        const trailResult = await getTrails(auth, parkId);
        const trailsWithLandmarks: TrailWithLandmarks[] = [];
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < trailResult.length; i++) {
          const trail = trailResult[i];
          const trailLandmarks = await getTrailLandmarks(
            auth,
            parkId,
            trail.id
          );
          trailsWithLandmarks.push({
            ...trail,
            landmarks: trailLandmarks,
          });
        }
        setTrails(trailsWithLandmarks);
        if (trailResult.length > 0) {
          setSelectedTrailId(trailResult[0].id);
        }
      } catch (err) {
        if (err instanceof HttpError && err.status === 404) {
          navigate("/notfound");
          return;
        }
        console.log(err);
        setAppError("Unable to load park data");
      }

      window.onbeforeunload = function () {
        if (
          Object.keys(pendingParkChanges.current).length > 0 ||
          Object.keys(pendingLandmarkChanges.current).length > 0 ||
          Object.keys(pendingTrailChanges.current).length > 0 ||
          saving
        ) {
          void immediatelySavePendingChanges();
          return true;
        }
      };
      return function () {
        window.onbeforeunload = null;
      };
    })();
  }, []);

  if (isNaN(parkId)) {
    navigate("/notfound");
    return null;
  }

  if (typeof park === "undefined") {
    return (
      <div style={{ height: "100vh" }}>
        <EditorError errorMessage={appError} />
        <LoadingScreen />
      </div>
    );
  }

  let activePanel;
  switch (activeTab) {
    case tabs.INFO:
      activePanel = (
        <ParkInformation park={park} saveParkChanges={saveParkChanges} />
      );
      break;
    case tabs.METADATA:
      activePanel = (
        <AppMetadata park={park} saveParkChanges={saveParkChanges} />
      );
      break;
    case tabs.LANDMARKS:
      activePanel = (
        <Landmarks
          landmarks={landmarks}
          addLandmark={addLandmark}
          deleteLandmark={removeLandmark}
          parkBoundary={park.boundary}
          saveLandmarkChanges={saveLandmarkChanges}
          selectedId={selectedLandmarkId}
          setSelectedId={setSelectedLandmarkId}
        />
      );
      break;
    case tabs.TRAILS:
      activePanel = (
        <Trails
          trails={trails}
          landmarks={landmarks}
          addTrail={addTrail}
          deleteTrail={removeTrail}
          addTrailLandmark={addTrailLandmark}
          removeTrailLandmark={removeTrailLandmark}
          updateTrailLandmark={updateTrailLandmark}
          saveTrailChanges={saveTrailChanges}
          selectedId={selectedTrailId}
          setSelectedId={setSelectedTrailId}
        />
      );
      break;
    default:
      activePanel = <ExportApp />;
  }

  return (
    <>
      <EditorError errorMessage={appError} />
      <EditorNav
        returnToDashboard={returnToDashboard}
        saving={saving}
        parkLastUpdated={lastUpdated}
      />
      <FillContainer>
        <EditorLayout>
          <GridColumn
            style={{
              backgroundColor: chroma(leftNavColor).desaturate(0.5).hex(),
            }}
          >
            <nav>
              <NavButton
                className={activeTab === tabs.INFO ? "active" : ""}
                onClick={() => {
                  setActiveTab(tabs.INFO);
                }}
              >
                Park information
              </NavButton>
              <NavButton
                className={activeTab === tabs.METADATA ? "active" : ""}
                onClick={() => {
                  setActiveTab(tabs.METADATA);
                }}
              >
                App metadata
              </NavButton>
              <NavButton
                className={activeTab === tabs.LANDMARKS ? "active" : ""}
                disabled={park.boundary.length === 0}
                onClick={() => {
                  setActiveTab(tabs.LANDMARKS);
                }}
              >
                Landmarks
              </NavButton>
              <NavButton
                className={activeTab === tabs.TRAILS ? "active" : ""}
                disabled={park.boundary.length === 0}
                onClick={() => {
                  setActiveTab(tabs.TRAILS);
                }}
              >
                Trails
              </NavButton>
              <NavButton
                className={activeTab === tabs.EXPORT ? "active" : ""}
                disabled={park.boundary.length === 0}
                onClick={() => {
                  setActiveTab(tabs.EXPORT);
                }}
              >
                Export iOS App
              </NavButton>
            </nav>
          </GridColumn>
          <GridColumn>{activePanel}</GridColumn>
        </EditorLayout>
      </FillContainer>
    </>
  );
}
