import React from "react";
import PhonePreview from "../../components/PhonePreview";
import styled from "styled-components";
import Park from "../../core/Park";
import { boldWeight, containerColor, goldColor, shadowColor } from "../../components/Theme";

interface ParkInformationPreviewProps {
  park: Park | undefined;
}

const Banner = styled.div`
  padding: 1rem;
  font-size: 20pt;
  font-weight: ${boldWeight};
  position: absolute;
  width: 100%;
  text-align: center;
  color: ${containerColor};
  text-shadow: 0px 0px 4px ${shadowColor};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: cover;
`;

const ImageWrapper = styled.div`
  height: 270px;
  overflow: hidden;
  border-bottom: 4px solid ${goldColor};
`;

const Block = styled.div`
  white-space: pre-wrap;
  margin-bottom: 1rem;
`;
const Content = styled.div`
  padding: 1rem;
`;

export default function ParkInformationPreview({
  park,
}: ParkInformationPreviewProps) {
  return (
    <PhonePreview>
      <Banner>About {park?.name}</Banner>
      <ImageWrapper>
        <Image src={park?.imageUrl} alt={park?.imageAltText} />
      </ImageWrapper>
      <Content>
        <div>{park?.name}</div>
        <Block>{park?.address}</Block>
        <Block>{park?.description}</Block>
      </Content>
    </PhonePreview>
  );
}
