import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import PhonePreview from "../../components/PhonePreview";
import styled from "styled-components";
import { MapContainer, TileLayer, useMapEvents, useMap } from "react-leaflet";
import { LatLngBoundsLiteral } from "leaflet";
import "leaflet/dist/leaflet.css";
import TextInput from "../../components/TextInput";
import Coordinate from "../../core/Coordinate";
import Park from "../../core/Park";
import Button from "../../components/Button";
import { geocode, PatchParkRequest } from "../../core/Services";

interface BoundaryPickerProps {
  park: Park | undefined;
  saveParkChanges: (data: PatchParkRequest) => void;
}

const GeocoderWrapper = styled.div`
  width: 230px;

  position: absolute;
  z-index: 1000;
  top: 10px;
  left: 55px;
  form {
    display: flex;
  }
  button {
    align-self: center;
    padding: 0.5rem;
  }
`;
const GeocodeFormField = styled(TextInput)`
  flex-grow: 1;
`;

function MapHooks({ saveParkChanges }: BoundaryPickerProps) {
  const map = useMapEvents({
    moveend: () => {
      const bounds = map.getBounds();
      const NE = bounds.getNorthEast();
      const SW = bounds.getSouthWest();
      const NELat = Number(NE.lat.toFixed(5));
      const SWLat = Number(SW.lat.toFixed(5));
      const NELong = Number(NE.lng.toFixed(5));
      const SWLong = Number(SW.lng.toFixed(5));
      saveParkChanges({
        boundary: [
          { latitude: SWLat, longitude: NELong },
          { latitude: NELat, longitude: NELong },
          { latitude: NELat, longitude: SWLong },
          { latitude: SWLat, longitude: SWLong },
          { latitude: SWLat, longitude: NELong },
        ],
      });
    },
  });
  return null;
}

function Geocoder() {
  const searchField = useRef<HTMLInputElement>(null);
  const map = useMap();
  async function search() {
    let searchValue = searchField.current?.value.trim() ?? "";
    searchValue = searchValue.replace("(Not found)", "").trim();

    if (!searchField.current || searchValue === "") {
      return;
    }
    searchField.current.value = `${searchValue} (Searching...)`;
    const result = await geocode(searchValue);
    if (typeof result !== "undefined") {
      const bbox = result.boundingbox;
      const minLat = Number(bbox[0]);
      const maxLat = Number(bbox[1]);
      const minLon = Number(bbox[2]);
      const maxLon = Number(bbox[3]);

      map.fitBounds([
        [minLat, minLon],
        [minLat, maxLon],
        [maxLat, maxLon],
        [maxLat, minLon],
      ]);
      searchField.current.value = `${searchValue}`;
    } else {
      searchField.current.value = `${searchValue} (Not found)`;
    }
  }

  return (
    <GeocoderWrapper>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          void search();
        }}
      >
        <GeocodeFormField
          placeholder="Location search"
          id="search"
          type="text"
          ref={searchField}
        />
        <Button type="submit">
          <span className="visually-hidden">Search</span>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </form>
    </GeocoderWrapper>
  );
}

const defaultBounds: LatLngBoundsLiteral = [
  [40.83251, -74.93774],
  [39.48072, -75.76171],
];

export default function BoundaryPicker({
  park,
  saveParkChanges,
}: BoundaryPickerProps) {
  if (typeof park === "undefined") {
    return null;
  }

  let bounds = defaultBounds;
  if (park.boundary.length > 0) {
    bounds = park.boundary.map((coordinate: Coordinate) => {
      return [coordinate.latitude, coordinate.longitude];
    });
  }

  return (
    <>
      <PhonePreview>
        <MapContainer bounds={bounds} style={{ height: "628px" }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MapHooks park={park} saveParkChanges={saveParkChanges} />
          <Geocoder />
        </MapContainer>
      </PhonePreview>
    </>
  );
}
