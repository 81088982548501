import React, { useRef, MouseEvent, useState } from "react";
import styled from "styled-components";
import Button from "../components/Button";
import TextFormField from "../components/TextFormField";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthProvider";
import parseErrorMessage from "../core/parseErrorMessage";
import ConfirmationCodeForm from "./auth/ConfirmationCodeForm";
import {
  postConfirmSignUp,
  postResendConfirmationCode,
} from "../core/Services";

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
`;

const Separator = styled.hr`
  margin: 1.5rem 0;
`;

export default function SignIn() {
  const [errorText, setErrorText] = useState("");
  const [showCodeForm, setShowCodeForm] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const auth = useAuth();

  const emailField = useRef<HTMLInputElement>(null);
  const passwordField = useRef<HTMLInputElement>(null);

  async function signIn(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const email = emailField.current?.value ?? "";
    const password = passwordField.current?.value ?? "";

    try {
      await auth.signIn(email, password);
      navigate("/dashboard");
    } catch (err) {
      if (
        err instanceof Error &&
        err.message.includes("UserNotConfirmedException")
      ) {
        // User signed up but did not confirm their account.  Resend the confirmation code to their email and show the confirmation code form
        setEmail(email);
        setPassword(password);
        try {
          await postResendConfirmationCode(email);
          setShowCodeForm(true);
        } catch (newErr) {
          setErrorText(parseErrorMessage(err));
          console.log(newErr);
        }
      } else {
        setErrorText(parseErrorMessage(err));
      }
    }
  }

  async function submitCode(code: string) {
    await postConfirmSignUp(email, code);
    await auth.signIn(email, password);
    navigate("/dashboard");
  }

  if (showCodeForm) {
    return <ConfirmationCodeForm email={email} submitCode={submitCode} />;
  }

  return (
    <>
      <div>
        <h1>Sign in</h1>
        <form>
          <TextFormField
            id="email"
            label="Email"
            name="email"
            type="text"
            ref={emailField}
          />
          <TextFormField
            id="password"
            label="Password"
            name="password"
            type="password"
            ref={passwordField}
          />
          <ButtonRow>
            <Button onClick={(e) => void signIn(e)}>Sign in</Button>
            <Link to="/reset-password">Forgot password?</Link>
          </ButtonRow>
        </form>
      </div>
      <p className="error-text">{errorText}</p>
      <Separator />
      <p>
        Need an account? <Link to="/sign-up">Sign up</Link>{" "}
      </p>
    </>
  );
}
