import React, { useRef, useState, useMemo, useEffect } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import {
  latLngBounds,
  marker,
  LatLngBoundsLiteral,
  LatLngExpression,
  Marker,
  Map,
  Icon,
  LeafletMouseEvent,
} from "leaflet";
import "leaflet/dist/leaflet.css";
import Coordinate from "../../core/Coordinate";
import { Landmark } from "../../core/Landmark";
import { PatchLandmarkRequest } from "../../core/Services";

interface CoordinatePickerProps {
  parkBoundary: Coordinate[] | undefined;
  landmark: Landmark | undefined;
  saveLandmarkChanges: (data: PatchLandmarkRequest) => void;
}

const defaultBounds: LatLngBoundsLiteral = [
  [40.83251, -74.93774],
  [39.48072, -75.76171],
];

export default function CoordinatePicker({
  landmark,
  parkBoundary = [],
  saveLandmarkChanges,
}: CoordinatePickerProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = useState<Map | null>(null);
  const onClick = useRef<(e: LeafletMouseEvent) => void>();
  const landmarkPoint = useRef<Marker | undefined>();

  if (map) {
    map.off("click", onClick.current); //remove old event listeners
    const newListener = (e: LeafletMouseEvent) => {
      const pos = e.latlng;
      saveLandmarkChanges({
        longitude: pos.lng,
        latitude: pos.lat,
      });
    };
    onClick.current = newListener;

    map.on("click", newListener);

    if (
      landmark &&
      typeof landmark.latitude !== "undefined" &&
      typeof landmark.longitude !== "undefined"
    ) {
      const landmarkPosition: LatLngExpression = [
        landmark.latitude,
        landmark.longitude,
      ];
      if (typeof landmarkPoint.current === "undefined") {
        const newMarker = marker(landmarkPosition, {
          draggable: true,
          icon: new Icon({
            iconUrl: `${process.env.PUBLIC_URL}/images/marker-icon.png`,
            shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
            iconAnchor: [12, 43],
          }),
        });
        landmarkPoint.current = newMarker;
        map.addLayer(newMarker);
      } else {
        landmarkPoint.current.setLatLng(landmarkPosition);
      }
      landmarkPoint.current.off();
      landmarkPoint.current.on("click", (e: LeafletMouseEvent) => {
        e.originalEvent.stopPropagation();
        e.originalEvent.preventDefault();
      });
      landmarkPoint.current.on("dragend", () => {
        const pos = landmarkPoint.current?.getLatLng();
        if (pos) {
          saveLandmarkChanges({
            longitude: pos.lng,
            latitude: pos.lat,
          });
        }
      });
    } else if (landmarkPoint.current) {
      map.removeLayer(landmarkPoint.current);
      landmarkPoint.current = undefined;
    }
  }

  let bounds = defaultBounds;
  if (parkBoundary.length > 0) {
    bounds = parkBoundary.map((coordinate: Coordinate) => {
      return [coordinate.latitude, coordinate.longitude];
    });
  }
  if (
    landmark &&
    typeof landmark.latitude !== "undefined" &&
    typeof landmark.longitude !== "undefined"
  ) {
    bounds.push([landmark.latitude, landmark.longitude]);
  }

  const llbounds = latLngBounds(bounds);
  const center = llbounds.getCenter();
  //reposition bounds to be centered on park in a landscape view
  bounds = [
    [center.lat, llbounds.getWest()],
    [center.lat, llbounds.getEast()],
  ];

  useEffect(() => {
    if (map) {
      map.fitBounds(bounds);
    }
  }, [landmark?.id]);

  const displayMap = useMemo(
    () => (
      <MapContainer bounds={bounds} style={{ height: "300px" }} ref={setMap}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    ),
    []
  );

  if (typeof landmark === "undefined") {
    return null;
  }

  return displayMap;
}
