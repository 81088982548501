import React from "react";
import TwoPanelEditorPage from "./TwoPanelEditorPage";
import ParkInformationPreview from "./ParkInformationPreview";
import TextAreaFormField from "../../components/TextAreaFormField";
import TextFormField from "../../components/TextFormField";
import { PatchParkRequest } from "../../core/Services";
import Park from "../../core/Park";

interface ParkInfoProps {
  park: Park | undefined;
  saveParkChanges: (data: PatchParkRequest) => void;
}

function LeftPanel({ saveParkChanges, park }: ParkInfoProps) {
  return (
    <>
      <h2>Park information</h2>
      <TextFormField
        id="name"
        type="text"
        label="Name"
        defaultValue={park?.name}
        onChange={(e) => {
          const newName = e.target.value.trim();
          if (newName !== "") {
            saveParkChanges({ name: e.target.value });
          }
        }}
      />
      <TextAreaFormField
        id="address"
        label="Address"
        defaultValue={park?.address}
        onChange={(e) => {
          saveParkChanges({ address: e.target.value });
        }}
      />
      <TextAreaFormField
        id="about"
        label="About"
        defaultValue={park?.description}
        onChange={(e) => {
          saveParkChanges({ description: e.target.value });
        }}
      />
      <TextFormField
        id="url"
        type="text"
        label="Park photo URL"
        defaultValue={park?.imageUrl}
        onChange={(e) => {
          saveParkChanges({ imageUrl: e.target.value });
        }}
      />
      <TextFormField
        id="altText"
        type="text"
        label="Photo alt text"
        defaultValue={park?.imageAltText}
        onChange={(e) => {
          saveParkChanges({ imageAltText: e.target.value });
        }}
      />
    </>
  );
}

function RightPanel(props: ParkInfoProps) {
  return (
    <>
      <h2>Preview</h2>
      <ParkInformationPreview {...props} />
    </>
  );
}

export default function ParkInformation(props: ParkInfoProps) {
  return (
    <TwoPanelEditorPage
      leftPanel={<LeftPanel {...props} />}
      rightPanel={<RightPanel {...props} />}
    ></TwoPanelEditorPage>
  );
}
